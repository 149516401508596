import axios from "axios";
import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";

const initGetProduct = () => {
  return {
    type: actionTypes.INIT_GET_PRODUCT,
    loading: true
  };
};

const successGetProduct = data => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCT,
    loading: false,
    product: data
  };
};

const failGetProduct = err => {
  return {
    type: actionTypes.FAIL_GET_PRODUCT,
    loading: false,
    error: err
  };
};

export const getProduct = id => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };

  return dispatch => {
    dispatch(initGetProduct());
    axios
      .get(`product/${id}`, config)
      .then(response => dispatch(successGetProduct(response.data.data)))
      .catch(err => dispatch(failGetProduct(err.message)));
  };
};
