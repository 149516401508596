import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  productVisibility: [],
  productType: [],
  error: null
};

const initGetOption = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetVisibility = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    productVisibility: action.productVisibility
  };
};

const successGetType = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    productType: action.productType
  };
};

const failGetOption = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_OPTION:
      return initGetOption(state, action);
    case actionTypes.SUCCESS_GET_VISIBILITY:
      return successGetVisibility(state, action);
    case actionTypes.SUCCESS_GET_PRODUCT_TYPE:
      return successGetType(state, action);
    case actionTypes.FAIL_GET_OPTION:
      return failGetOption(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
