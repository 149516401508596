import axios from "axios";

import { getToken } from "../../utils";
import * as actionTypes from "../actionTypes";
import { getAttributeGroup } from "../add_product/attribute_action";
import { getCatalogAttribute } from "./catalog_attribute_action";

const initGetDetail = () => {
  return {
    type: actionTypes.INIT_GET_CATALOG_ATTRIBUTE_DETAILS,
    getting: true
  };
};

const successGetDetail = data => {
  return {
    type: actionTypes.SUCCESS_GET_CATALOG_ATTRIBUTE_DETAILS,
    getting: false,
    data: data
  };
};

const failGetDetail = error => {
  return {
    type: actionTypes.FAIL_GET_CATALOG_ATTRIBUTE_DETAILS,
    getting: false,
    detailError: error
  };
};

export const getCatalogAttribuetDetail = id => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  return dispatch => {
    dispatch(initGetDetail());
    axios
      .get(`attribute-group/edit/${id}`, config)
      .then(response => dispatch(successGetDetail(response.data.data)))
      .catch(err => dispatch(failGetDetail(err.message)));
  };
};

//Post catalog attribute;
export const postCatalogAttribute = (data, id, inProdcutCreate) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  const formData = new FormData();

  // data.attribute = JSON.stringify(data.attribute)

  Object.entries(data).forEach(([key, val]) => {
    formData.append(key, val);
    if (key === "attribute") formData.append(key, JSON.stringify(val));
  });

  if (id) {
    formData.append("_method", "PUT");
    formData.append("id", id);
  }

  // for (let [key, val] of formData.entries()) {
  //   console.log(key, val)
  // }

  return dispatch => {
    dispatch({ type: actionTypes.INIT_POST_CATALOG_ATTRIBUTE, loading: true });
    axios
      .post("attribute-group", formData, config)
      .then(response => {
        dispatch({
          type: actionTypes.SUCCESS_POST_CATALOG_ATTRIBUTE,
          loading: false,
          success: true
        });
        if (inProdcutCreate) {
          dispatch(getCatalogAttribute());
          dispatch(getAttributeGroup());
        }
      })
      .catch(err => {
        console.log(err.response);
        dispatch({
          type: actionTypes.FAIL_POST_CATALOG_ATTRIBUTE,
          loading: false,
          error: true
        });
      });
  };
};
