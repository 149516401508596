import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import * as actionTypes from "./actionTypes";

const initLogIn = () => {
  return {
    type: actionTypes.INIT_LOG_IN,
    loading: true,
  };
};

export const successLogIn = (data) => {
  return {
    type: actionTypes.SUCCESS_LOG_IN,
    loading: false,
    idToken: data.access_token,
    user: data.user,
  };
};
export const successFirebaseLogIn = (data) => {
  return {
    type: actionTypes.SUCCESS_LOG_IN,
    loading: false,
    idToken: data.accessToken,
    user: {
      name: data.displayName ?? "",
      email: data.email,
      emailVerified: data.emailVerified,
      uid: data.uid,
      providerData: data.providerData,
    },
  };
};

export const failLogIn = (error) => {
  return {
    type: actionTypes.FAIL_LOG_IN,
    loading: false,
    error: error,
  };
};

export const logIn = (data, setIsAuthenticated) => {
  return (dispatch) => {
    dispatch(initLogIn());
    localStorage.setItem("keep", data.keepMe);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((res) => {
        setIsAuthenticated(true);
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(failLogIn(errorMessage));
      });
  };
};

export const register = (data, redirect) => {
  return (dispatch) => {
    dispatch(initLogIn());
    //console.log(data);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((res) => {
        redirect({
          pathname: "/auth/registration",
          state: { message: "Account created successfully" },
        });
      })
      .catch((error) => {
        let errorMessage = error.message;
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/email-already-in-use":
            errorMessage = "email already exist";
            break;
          case "auth/invalid-email":
            errorMessage = "invalid email";
            break;
          case "auth/weak-password":
            errorMessage = "weak password";
            break;
          default:
            errorMessage = "Oops, something went wrong";
        }
        dispatch(failLogIn(errorMessage));
      });
  };
};

export const googleAuth = (setIsAuthenticated) => {
  return (dispatch) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        setIsAuthenticated(true);
      })
      .catch((error) => {
        console.log(error + "\n", error.message, "\n#######################");
        dispatch(failLogIn("Error"));
      });
  };
};
export const resetPassword = (email, redirect) => {
  return (dispatch) => {
    sendPasswordResetEmail(getAuth(), email)
      .then((res) => {
        console.log(res);
        redirect({
          pathname: "/auth/login",
          state: { message: "Password reset email sent" },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(failLogIn("User dose not exist"));
      });
  };
};

export const logOut = () => {
  return (dispatch) => {
    signOut(getAuth())
      .then((response) => {
        localStorage.removeItem("idToken");
        sessionStorage.removeItem("idToken");
        window.location.reload();
      })
      .catch((err) => {
        localStorage.removeItem("idToken");
        sessionStorage.removeItem("idToken");
        window.location.reload();
      });
  };
};
