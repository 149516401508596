import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAZBjozImiGwlbTYtSesymHqAJ5mE7HMEI",
  authDomain: "pcari-71145.firebaseapp.com",
  databaseURL: "https://pcari-71145.firebaseio.com",
  projectId: "pcari-71145",
  storageBucket: "pcari-71145.appspot.com",
  messagingSenderId: "568647603184",
  appId: "1:568647603184:web:571e2b45a2fb7f122e4335",
  measurementId: "G-SG4WVG00JR"
};

export const app = initializeApp(firebaseConfig);
