import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  product: {},
  error: null
};

const initGetProduct = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetProduct = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    product: action.product
  };
};

const failGetProduct = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRODUCT:
      return initGetProduct(state, action);
    case actionTypes.SUCCESS_GET_PRODUCT:
      return successGetProduct(state, action);
    case actionTypes.FAIL_GET_PRODUCT:
      return failGetProduct(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
