import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_addvertis: false,
  addvertis_data: [],
  error_addvertis: null,
  addvertis_types: []
};

const initGetAddvertisement = (state, action) => {
  return {
    ...state,
    loading_addvertis: action.loading_addvertis
  };
};

const successGetAddvertisement = (state, action) => {
  return {
    ...state,
    loading_addvertis: action.loading_addvertis,
    addvertis_data: action.addvertis_data
  };
};

const failGetAddvertisement = (state, action) => {
  return {
    ...state,
    loading_addvertis: action.loading_addvertis,
    error_addvertis: action.error_addvertis
  };
};
//
const successGetAddvertisementTypes = (state, action) => {
  return {
    ...state,
    addvertis_types: action.addvertis_types
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_ADDVERTISMENT:
      return initGetAddvertisement(state, action);
    case actionTypes.SUCCESS_GET_ADDVERTISMENT:
      return successGetAddvertisement(state, action);
    case actionTypes.FAIL_GET_ADDVERTISMENT:
      return failGetAddvertisement(state, action);
    //
    case actionTypes.SUCCESS_GET_ADDVERTISMENT_TYPES:
      return successGetAddvertisementTypes(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
