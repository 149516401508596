import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_orders: false,
  orders_data: { orders: [], shipping: [], parcel: [], drop: [], cancel: [] },
  error_orders: null,
  //order
  loading_order: false,
  order_data: null,
  error_order: null,
  //shipping vendor
  loading_shipping_vendors: false,
  data_shipping_vendors: [],
  error_shipping_vendors: null,
};

//orders
const initGetOrders = (state, action) => {
  return {
    ...state,
    loading_orders: action.loading_orders,
  };
};

const successGetOrders = (state, action) => {
  return {
    ...state,
    loading_orders: action.loading_orders,
    orders_data: {
      ...state.orders_data,
      [action.key]: action.orders_data,
    },
  };
};

const failGetOrders = (state, action) => {
  return {
    ...state,
    loading_order: action.loading_order,
    error_orders: action.error_orders,
  };
};

//order
const initGetOrder = (state, action) => {
  return {
    ...state,
    loading_order: action.loading_order,
  };
};

const successGetOrder = (state, action) => {
  return {
    ...state,
    loading_order: action.loading_order,
    order_data: action.order_data,
    error_order: action.error_order,
  };
};

const failGetOrder = (state, action) => {
  return {
    ...state,
    loading_order: action.loading_order,
    order_data: action.order_data,
    error_order: action.error_order,
  };
};
const resetOrder = (state, action) => {
  return {
    ...state,
    loading_shipping_vendors: false,
    data_shipping_vendors: [],
    order_data: null,
    error_shipping_vendors: null,
  };
};

//shipping vendors
const initGetShippingVendors = (state, action) => {
  return {
    ...state,
    loading_shipping_vendors: action.loading_shipping_vendors,
  };
};

const successGetShippingVendors = (state, action) => {
  return {
    ...state,
    loading_shipping_vendors: action.loading_shipping_vendors,
    data_shipping_vendors: action.data_shipping_vendors,
  };
};

const failGetShippingVendors = (state, action) => {
  return {
    ...state,
    loading_shipping_vendors: action.loading_shipping_vendors,
    error_shipping_vendors: action.error_shipping_vendors,
  };
};
//order

const Reducer = (state = initState, action) => {
  switch (action.type) {
    //orders
    case actionTypes.INIT_GET_ORDERS:
      return initGetOrders(state, action);
    case actionTypes.SUCCESS_GET_ORDERS:
      return successGetOrders(state, action);
    case actionTypes.FAIL_GET_ORDERS:
      return failGetOrders(state, action);
    //order
    case actionTypes.INIT_GET_ORDER:
      return initGetOrder(state, action);
    case actionTypes.SUCCESS_GET_ORDER:
      return successGetOrder(state, action);
    case actionTypes.FAIL_GET_ORDER:
      return failGetOrder(state, action);
    case actionTypes.RESET_ORDER:
      return resetOrder(state, action);
    //shipping vendors
    case actionTypes.INIT_GET_SHIPPING_VENDORS:
      return initGetShippingVendors(state, action);
    case actionTypes.SUCCESS_GET_SHIPPING_VENDORS:
      return successGetShippingVendors(state, action);
    case actionTypes.FAIL_GET_SHIPPING_VENDORS:
      return failGetShippingVendors(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
