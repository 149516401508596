import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_dashboard: false,
  sales: null,
  product: [],
  category: [],
  status: null,
  error_dashboard: null,
  //
  loading_balane: false,
  vendor_balance: null,
  error_balance: null
};

const initGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard
  };
};
const successGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard,
    sales: action.sales,
    product: action.product,
    category: action.category,
    status: action.status
  };
};
const failGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard,
    error_dashboard: action.error_dashboard
  };
};
//
const initBalance = (state, action) => {
  return {
    ...state,
    loading_balane: action.loading_balane
  };
};
const successBalance = (state, action) => {
  return {
    ...state,
    loading_balane: action.loading_balane,
    vendor_balance: action.vendor_balance,
    error_balance: action.error_balance
  };
};
const failBalance = (state, action) => {
  return {
    ...state,
    loading_balane: action.loading_balane,
    vendor_balance: action.vendor_balance,
    error_balance: action.error_balance
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_DASHBOARD:
      return initGetDashboard(state, action);
    case actionTypes.SUCCSESS_GET_DASHBOARD:
      return successGetDashboard(state, action);
    case actionTypes.FAIL_GET_DASHBOARD:
      return failGetDashboard(state, action);
    //
    case actionTypes.INIT_GET_VENDOR_BALANCE:
      return initBalance(state, action);
    case actionTypes.SUCCESS_GET_VENDOR_BALANCE:
      return successBalance(state, action);
    case actionTypes.FAIL_GET_VENDOR_BALANCE:
      return failBalance(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
