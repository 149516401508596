import axios from "axios";
import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";

const initGetAttribute = () => {
  return {
    type: actionTypes.INIT_GET_ATTRIBUTE,
    loading: true
  };
};

const successGenerateAtrribute = data => {
  return {
    type: actionTypes.SUCCESS_GENERATE_ATTRIBUTE,
    loading: false,
    data: data
  };
};

const failGenerateAttribute = error => {
  return {
    type: actionTypes.FAIL_GET_ATTRIBUTE,
    loading: false,
    error: error
  };
};

export const reSetGenerateAttribute = () => {
  return {
    type: actionTypes.RESET_GENERATE_ATTRIBUTE
  };
};

export const generateAttribute = (group, sku) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  const newStrcture = { label: [], id: [] };
  Object.values(group).forEach(attributs => {
    const innerLabel = [],
      innerId = [];
    attributs.forEach(attribut => {
      innerLabel.push(attribut.label);
      innerId.push(attribut.id);
    });
    newStrcture.label.push(innerLabel);
    newStrcture.id.push(innerId);
  });

  const data = { group: JSON.stringify(newStrcture), sku };

  return dispatch => {
    dispatch(initGetAttribute());

    axios
      .post("attribute-group/generate/variant", data, config)
      .then(response => dispatch(successGenerateAtrribute(response.data.data)))
      .catch(error => {
        dispatch(failGenerateAttribute(error.message));
        console.log("Request", error.request, "\n", "Response", error.response);
      });
  };
};
