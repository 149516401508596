import axios from "axios";

import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";

const initGetCategoryDetail = () => {
  return {
    type: actionTypes.INIT_GET_CATALOG_CATEGORY_DETAILS,
    getting: true,
  };
};

const successGetCategoryDetail = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_CATALOG_CATEGORY_DETAILS,
    getting: false,
    data: data,
  };
};

const failGetCategoryDetail = (error) => {
  return {
    type: actionTypes.FAIL_GET_CATALOG_CATEGORY_DETAILS,
    getting: false,
    error: error,
  };
};

export const getCategoryDetail = (id) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return (dispatch) => {
    dispatch(initGetCategoryDetail());
    axios
      .get(`vendor/category/${id}`, config)
      .then((response) =>
        dispatch(successGetCategoryDetail(response.data.data))
      )
      .catch((err) => {
        console.log(err.message, err.response);
        dispatch(failGetCategoryDetail(err.message));
      });
  };
};

//post :

export const addCatalogCategory = (data, redirect) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();

  Object.entries(data).map(([key, val]) => formData.append(key, val));

  return (dispatch) => {
    dispatch({ type: actionTypes.INIT_POST_CATALOG_CATEGORY, loading: true });
    axios
      .post("vendor/category", formData, config)
      .then((response) => {
        if (redirect) {
          redirect(response.data.data.id);
        } else {
          dispatch({
            type: actionTypes.SUCCESS_POST_CATALOG_CATEGORY,
            loading: false,
            success: true,
          });
        }
      })
      .catch((err) => {
        //console.log(err.response);
        dispatch({
          type: actionTypes.FAIL_POST_CATALOG_CATEGORY,
          loading: false,
          error: err.message,
        });
      });
  };
};
