import React, { useEffect, useMemo } from "react";
import axios from "axios";
import objectPath from "object-path";
import { useHistory, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { connectToClient, getStreamToken } from "../../../redux/actions";
// import {StickyToolbar} from "./extras/StickyToolbar";

export function Layout({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const uiService = useHtmlClassService();
  const vendor = useSelector((state) => state.auth.user);
  const {chatClient, streamToken} = useSelector((state) => state.streamChatData);
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  useEffect(() => {
    axios.interceptors.response.use(undefined, (err) => {
      if (err.response && err.response.status === 500) {
        history.push(`/server-error?${err.message}`);
      } else if (err.response && err.response.status === 401) {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          user
            .getIdToken()
            .then((res) => {
              if (localStorage.getItem("keep") === "true")
                localStorage.setItem("idToken", res);
              else sessionStorage.setItem("idToken", res);
            })
            .catch((err) => signOut(auth));
        }
      }
      return Promise.reject(err);
    });
  }, [history]);

  useEffect(() => {
    dispatch(getStreamToken());
  }, [dispatch]);

  useEffect(() => {
    if (streamToken) {
      dispatch(connectToClient());
    }
  }, [dispatch, streamToken, chatClient]);

  let missingInfoAlert = null;
  if (vendor) {
    const checkFor = ["company_office_no", "company_phone_no", "shop_name"];
    const missingFeild = [];
    checkFor.forEach((feild) => {
      if (!vendor[feild]) {
        let formatFeild = feild.replace(/_/g, " ");
        formatFeild = formatFeild.replace("company", "");
        formatFeild = formatFeild.replace("no", "number");
        missingFeild.push(formatFeild);
      }
    });
    if (missingFeild.length > 0) {
      missingInfoAlert = (
        <Alert
          variant="danger"
          className="mb-0 mb-lg-2 rounded-0 cursor-pointer"
          onClick={(e) => history.push("/user-profile/personal-information")}
        >
          <p className="mb-0">
            Seems like you are missing{" "}
            <span className="text-capitalice">{missingFeild.join(" , ")} </span>
            Click here to complete your profile.
          </p>
        </Alert>
      );
    }
    if (+vendor.is_verified === 0) {
      missingInfoAlert = (
        <Alert
          variant="danger"
          className="mb-0 mb-lg-2 rounded-0 cursor-pointer"
          onClick={(e) => history.push("/user-profile/personal-information")}
        >
          <p className="mb-0">
            Your account is not verified. Click here to update.
          </p>
        </Alert>
      );
    }
  }

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {pathname !== "/user-profile/personal-information" &&
              missingInfoAlert}
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <QuickPanel />
      <ScrollTop />
      {/* <StickyToolbar/> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
