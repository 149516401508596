import axios from "axios";
import { getToken } from "../utils";
import * as actionTypes from "./actionTypes";

const initGetShippers = () => {
  return {
    type: actionTypes.INIT_GET_SHIPPERS,
    shippers_loading: true,
  };
};
const succesGetShippers = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_SHIPPERS,
    shippers_loading: false,
    shippers_data: data,
    shippers_error: null,
  };
};
const failGetShippers = (error) => {
  return {
    type: actionTypes.FAIL_GET_SHIPPERS,
    shippers_loading: false,
    shippers_data: [],
    shippers_error: error,
  };
};

export const getShippers = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetShippers);
    axios
      .get("shipping_vendor", config)
      .then((res) => dispatch(succesGetShippers(res.data.data)))
      .catch((err) => {
        console.log(err.response);
        dispatch(failGetShippers());
      });
  };
};

export const updateShipperParent = (data, addToast) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  formData.append("shipping_vendor_id", data.shipping_vendor_id);
  formData.append("active", data.active);

  return (dispatch) => {
    axios
      .post("vendor_shipping_config_master", formData, config)
      .then((res) => {
        if (+data.active === 1)
          addToast(`${data.name} enabled`, {
            appearance: "success",
            autoDismiss: true,
          });
        else
          addToast(`${data.name} disabled`, {
            appearance: "error",
            autoDismiss: true,
          });
        dispatch(getShippers());
      });
  };
};

export const updateShipperChild = (data, addToast) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  formData.append("shipping_vendor_id", data.shipping_vendor_id);
  formData.append("child_id", data.child_id);
  formData.append("active", data.active);

  return (dispatch) => {
    axios.post("vendor_shipping_config", formData, config).then((res) => {
      if (+data.active === 1)
        addToast(`${data.name} enabled`, {
          appearance: "success",
          autoDismiss: true,
        });
      else
        addToast(`${data.name} disabled`, {
          appearance: "error",
          autoDismiss: true,
        });
      dispatch(getShippers());
    });
  };
};
