import { combineReducers } from "redux";
import AuthenticationReducer from "./reducers/authenticationReducer";
import VendorReducer from "./reducers/vendorReducer";
import ProductsReducer from "./reducers/productsReducer";
import ProductReducer from "./reducers/add_product/get_product_reducer";
import generatedAttributeReducer from "./reducers/add_product/generate_attribute_reducer";
import addProductReducer from "./reducers/add_product/add_product_reducer";
import SubmitFormReducer from "./reducers/add_product/add_product_submit_reducer";
import attributeGroupReducer from "./reducers/add_product/attribute_reducer";
import validationReducer from "./reducers/add_product/add_product_validation_reducer";
import productOptions from "./reducers/add_product/add_product_option_reducer";
import categoriesReducer from "./reducers/categoriesReducer";
import CatalogAttributeReducer from "./reducers/catalog_attribute/catalog_attribute_reducer";
import CatalogAttributeDetailReducer from "./reducers/catalog_attribute/catalog_attribute_detail_reducer";
import CatalogCategoriesReducer from "./reducers/catalgo_categories/catalog_categories_reducer";
import catalogCategoryDetailsReducer from "./reducers/catalgo_categories/catalog_category_detail_reducer";
import tagCatalogCategoryGetProductsReducer from "./reducers/catalgo_categories/catalog_category_products_reducer";
import catalogPromotionsReducer from "./reducers/catalogPromotionsReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import transactionReducer from "./reducers/transactionReducer";
import addvertismentReducer from "./reducers/addvertismentReducer";
import ordersReducer from "./reducers/ordersReducer";
import shippingConfigReducer from "./reducers/shippingConfigReducer";
import streamChatReducer from "./reducers/streamChatReducer";
import ratingReducer from "./reducers/ratingReducer";

const reducer = combineReducers({
  auth: AuthenticationReducer,
  vendor: VendorReducer,
  products: ProductsReducer,
  product: ProductReducer,
  generatedAttribute: generatedAttributeReducer,
  addProduct: addProductReducer,
  submitForm: SubmitFormReducer,
  attribute: attributeGroupReducer,
  validation: validationReducer,
  options: productOptions,
  categories: categoriesReducer,
  catalogAttribute: CatalogAttributeReducer,
  catalogAttributeDetails: CatalogAttributeDetailReducer,
  catalogCategories: CatalogCategoriesReducer,
  catalogCategoryDetails: catalogCategoryDetailsReducer,
  catalogCategoryProducts: tagCatalogCategoryGetProductsReducer,
  catalogPromotions: catalogPromotionsReducer,
  dashboard: dashboardReducer,
  transaction: transactionReducer,
  addvertisment: addvertismentReducer,
  orders: ordersReducer,
  shippingConfig: shippingConfigReducer,
  streamChatData: streamChatReducer,
  rating: ratingReducer,
});

export default reducer;
