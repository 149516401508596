import { createStore, applyMiddleware, compose } from "redux";
import axios from "axios";

import thunk from "redux-thunk";
import reducer from "./rootReducer";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const logger = (store) => {
  return (next) => {
    return (action) => {
      if (process.env.REACT_APP_ENV !== "production")
        console.log("ACTION", action);
      const result = next(action);
      if (process.env.REACT_APP_ENV !== "production")
        console.log("STATE", store.getState());
      return result;
    };
  };
};

const store = createStore(reducer, compose(applyMiddleware(thunk, logger)));

export default store;
