/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Form, Modal, Row, Button, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { addBalance } from "../../../../../redux/actions";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // const history = useHistory();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ amount: 50 });
  const closeModal = () => setShow(false);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Catalog */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Catalog</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/products", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
              />
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/promotions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/promotions">
            <span className="svg-icon menu-icon" style={{pointerEvents:'none'}}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")} />
            </span>
            <span className="menu-text">Promotions</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/attribute", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/attribute">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")}
              />
            </span>
            <span className="menu-text">Variations</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/category", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/category">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg")} />
            </span>
            <span className="menu-text">Shop Categories</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Sales</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/orders", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/orders">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
              />
            </span>
            <span className="menu-text">Orders</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/messages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{pathname:"/messages", state:null}}>
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}
              />
            </span>
            <span className="menu-text">Messages</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/rating", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{pathname:"/rating", state:null}}>
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/pcari/rating.svg")}
              />
            </span>
            <span className="menu-text">Review</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/advertisement", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/advertisement">
            <span className="svg-icon menu-icon" style={{pointerEvents:'none'}}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")} />
            </span>
            <span className="menu-text">Advertising</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Authoriztion */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Payment</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/top up", false)}`}
          aria-haspopup="true"
        >
          <span
            className="menu-link"
            onClick={event => {
              event.preventDefault();
              setShow(true);
            }}
          >
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("media/svg/icons/Shopping/Wallet2.svg")}
              />
            </span>
            <span className="menu-text">Top Up</span>
          </span>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/transaction", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transaction">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG
                src={toAbsoluteUrl("media/svg/icons/Shopping/Wallet3.svg")}
              />
            </span>
            <span className="menu-text">Transaction History</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Configuration</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/configuration", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/configuration">
            <span
              className="svg-icon menu-icon"
              style={{ pointerEvents: "none" }}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Compass.svg")} />
            </span>
            <span className="menu-text">Shipping Setting</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">My Store</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        {user && (
          <li className="menu-item" aria-haspopup="true">
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}seller/shop-products?seller=${user.id}`}
              className="menu-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="svg-icon menu-icon"
                style={{ pointerEvents: "none" }}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
              </span>
              <span className="menu-text">Visit My Store</span>
            </a>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/tools",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/tools">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">Tools</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Tools</span>
                </span>
              </li>
              {/*end::1 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/tools/fragile-logos"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={{ pathname: "/tools/fragile-logos" }}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Fragile Logos</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}

      <Modal show={show} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>Top up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="my-3">
            <Form.Group as={Col} md="10">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                as="select"
                onChange={(event) => {
                  setData({ amount: event.target.value });
                }}
              >
                {/* <option value=''></option> */}
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </Form.Control>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="outline-success"
            onClick={() => dispatch(addBalance(data))}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
