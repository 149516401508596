import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const Transactoin = lazy(() => import("./pages/transaction"));

const Messages = lazy(() => import("./modules/application/messages"));
const Rating = lazy(() => import("./modules/application/rating"));
const RatingDetail = lazy(() => import("./modules/application/rating_detail"));

const Orders = lazy(() => import("./modules/application/orders"));
const OrderPrint = lazy(() => import("./modules/application/order_print"));
const orderDetail = lazy(() => import("./modules/application/order_detail"));

// const AddAdvertisement = lazy(() =>
//   import("./modules/application/add_addvertisement")
// );
// const Advertisement = lazy(() => import("./modules/application/advertising"));
const ShippingConfig = lazy(() =>
  import("./modules/application/shipping_config")
);
// const AddPromotions = lazy(() => import("./modules/catalog/add_promotion"));
// const Promotions = lazy(() => import("./modules/catalog/promotions"));
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Products = lazy(() => import("./modules/products/products"));

// const Productss = lazy(() =>
//   import("./modules/products/productss")
// );

const newProdcut = lazy(() => import("./modules/add_product/add_product"));

const catalogAttribute = lazy(() => import("./modules/catalog/attribute"));

const newCatalogAttribute = lazy(() =>
  import("./modules/catalog/add_attribute")
);

const catalogCategories = lazy(() => import("./modules/catalog/categories"));
const newCatalogCategories = lazy(() =>
  import("./modules/catalog/add_category")
);
const fargileLogos = lazy(() => import("./modules/tools/fragile"));

const ServerError = lazy(() => import("./pages/500"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        {/* {
          <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        } */}

        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/transaction" component={Transactoin} />
        <Route path="/category/new" component={newCatalogCategories} />
        <Route path="/category/edit" component={newCatalogCategories} />
        <Route path="/category" component={catalogCategories} />
        {/* <Route path="/promotions/edit" component={AddPromotions} />
        <Route path="/promotions/new" component={AddPromotions} />
        <Route path="/promotions" component={Promotions} /> */}
        {/* <Route path="/advertisement/new" component={AddAdvertisement} />
        <Route path="/advertisement/edit" component={AddAdvertisement} />
        <Route path="/advertisement" component={Advertisement} /> */}
        <Route path="/orders" component={Orders} />
        <Route path="/messages" component={Messages} />
        <Route path="/rating/detail" component={RatingDetail} />
        <Route path="/rating" component={Rating} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/attribute/new" component={newCatalogAttribute} />
        <Route path="/attribute/edit" component={newCatalogAttribute} />
        <Route path="/attribute" component={catalogAttribute} />
        {/* <Route path="/productss" component={Productss} /> */}
        <Route path="/products/new" component={newProdcut} />
        <Route path="/products/edit" component={newProdcut} />
        <Route path="/products" component={Products} />
        <Route path="/server-error" component={ServerError} />
        {/* <Redirect to="error/error-v1" /> */}
        <Route path="/configuration" component={ShippingConfig} />
        <Route path="/print/order" component={OrderPrint} />
        <Route path="/order/detail" component={orderDetail} />
        <Route path="/tools/fragile-logos" component={fargileLogos} />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
