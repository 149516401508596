import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  error: null
};

const initGenerateAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGenerateAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data
  };
};

const failGenerateAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GENERATE_ATTRIBUTE:
      return initGenerateAttribute(state, action);
    case actionTypes.SUCCESS_GENERATE_ATTRIBUTE:
      return successGenerateAttribute(state, action);
    case actionTypes.FAIL_GENERATE_ATTRIBUTE:
      return failGenerateAttribute(state, action);
    case actionTypes.RESET_GENERATE_ATTRIBUTE:
      return { ...initState };
    default:
      return { ...state };
  }
};

export default Reducer;
