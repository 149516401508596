import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getToken } from "../utils";

const initGetProfile = () => {
  return {
    type: actionTypes.INIT_GET_PROFILE,
    loading_user: true,
  };
};

const successGetProfile = (data, fireUser) => {
  let providerData = [];

  if (fireUser) {
    fireUser.providerData.forEach((pData) =>
      providerData.push(pData.providerId)
    );
  }

  return {
    type: actionTypes.SUCCESS_GET_PROFILE,
    user: {
      ...data,
      name: data.name ?? data.shop_name,
      providerData: providerData,
    },
    loading_user: false,
  };
};

// const failGetProfile = (error) => {
//   return {
//     type: actionTypes.FAIL_GET_PROFILE,
//     loading_user: false,
//     error_user: error,
//   };
// };

export const getProfile = (runInit = false, fireUser) => {
  return (dispatch) => {
    if (runInit) dispatch(initGetProfile());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    axios
      .get("vendor", config)
      .then((response) =>
        dispatch(successGetProfile(response.data.data, fireUser))
      )

      // .catch(err => dispatch(failGetProfile(err.message)))
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

export const UpdateProfile = (data, addToast) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));
  // formData.append(data.type, data.image);
  formData.append("_method", "PUT");
  return (dispatch) => {
    axios
      .post("update", formData, config)
      .then((response) => {
        addToast("updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        dispatch(getProfile());
      })
      .catch((err) => {
        console.log("###response", err.response);
        let message = "Error!! please try again later";
        if (err?.response?.data?.messages) {
          message = err.response.data.messages.join(" ,");
        }
        addToast(message, {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };
};
