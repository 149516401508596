import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiFillCloseCircle,
} from "react-icons/ai";

import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import { failLogIn, register } from "../../../redux/actions/index";

const initialValues = {
  email: "",
  password: "",
  changepassword: "",
};

function Registration(props) {
  const { intl } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { user, error } = useSelector((state) => state.auth);
  const { setIsAuthenticated } = props;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const togglePasswordVisiblity = (mode) => {
    switch (mode) {
      case "password":
        setPasswordShown(passwordShown ? false : true);
        break;
      case "changepassword":
        setConfirmShown(confirmShown ? false : true);
        break;
      default:
        break;
    }
  };
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      enableLoading();
      // const storageMode = values.keepMe;
      const data = values;
      delete data.keepMe;
      const redirect = (to) => {
        history.push(to);
      };
      dispatch(register(data, redirect));
    },
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    dispatch(failLogIn(null));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      disableLoading();
      setIsAuthenticated(true);
    }
  }, [user, setIsAuthenticated]);

  useEffect(() => {
    if (error) {
      // console.log(error, 'LOGin COmp')
      disableLoading();
      setSubmitting(false);
    }
  }, [error, setSubmitting]);

  useEffect(() => {
    if (location.state && location.state.message) {
      setShowModal(true);
    }
  }, [location.state]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Register Account</h3>
        <p className="text-muted font-weight-bold">
          Enter your Email and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onFocus={() => {
          if (error) dispatch(failLogIn(null));
        }}
      >
        {error && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{error}</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container position-relative">
          <input
            type={passwordShown ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
          <span
            className="position-absolute"
            style={{
              right: "2.5rem",
              top: "1rem",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
            onClick={() => togglePasswordVisiblity("password")}
          >
            {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
          <span
            className="position-absolute"
            style={{
              right: "4.5rem",
              top: "1.5rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
            onClick={() => formik.setFieldValue("password", "")}
          >
            <AiFillCloseCircle />
          </span>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container position-relative">
          <input
            placeholder="Confirm Password"
            type={confirmShown ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
          />
          <span
            className="position-absolute"
            style={{
              right: "2.5rem",
              top: "1rem",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
            onClick={() => togglePasswordVisiblity("changepassword")}
          >
            {confirmShown ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
          <span
            className="position-absolute"
            style={{
              right: "4.5rem",
              top: "1.5rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
            onClick={() => formik.setFieldValue("changepassword", "")}
          >
            <AiFillCloseCircle />
          </span>
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/login"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Have an Account?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Register</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="p-0">
          <div
            className="w-100"
            style={{ height: "5.5rem", backgroundColor: "#32baa5" }}
          />
        </Modal.Header>
        <Modal.Body className="px-md-5 text-center">
          <h1 style={{ color: "#3DBA32" }} className="text-center my-5">
            REGISTRATION SUCCESSFUL!
          </h1>
          <img
            className="img-fluid my-5 mx-auto"
            src={toAbsoluteUrl("/media/done.png")}
            alt=""
          />
          <h4 className="text-center my-5">
            Yeahh! your online shop has been successfully created. You are now
            ready to start selling!
          </h4>
          <Button
            style={{ fontSize: "2rem" }}
            variant="success"
            className="my-5"
            size="lg"
            onClick={(e) => history.push("/auth/login")}
          >
            Starts Selling Now!
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default injectIntl(Registration);
