import axios from "axios";
import { getToken } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetDashoard = () => {
  return {
    type: actionTypes.INIT_GET_DASHBOARD,
    loading_dashboard: true
  };
};

const successGetDashoard = data => {
  return {
    type: actionTypes.SUCCSESS_GET_DASHBOARD,
    loading_dashboard: false,
    sales: data.sales,
    product: data.product,
    category: data.category,
    status: data.status
  };
};

const failGetDashoard = error => {
  return {
    type: actionTypes.FAIL_GET_DASHBOARD,
    loading_dashboard: false,
    error_dashboard: error
  };
};

export const getDashoard = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetDashoard());
    axios
      .get("dashboard", config)
      .then(res => dispatch(successGetDashoard(res.data)))
      .catch(err => dispatch(failGetDashoard(err.message)));
  };
};

//get balance
const initBalance = () => {
  return {
    type: actionTypes.INIT_GET_VENDOR_BALANCE,
    loading_balane: false
  };
};
const successBalance = data => {
  return {
    type: actionTypes.SUCCESS_GET_VENDOR_BALANCE,
    loading_balane: false,
    vendor_balance: data,
    error_balance: null
  };
};
const failBalance = error => {
  return {
    type: actionTypes.FAIL_GET_VENDOR_BALANCE,
    loading_balane: false,
    vendor_balance: null,
    error_balance: error
  };
};

export const getBalance = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      PcariCentralAuthorization:
        "E49jMThpWv4lrDGZ0Okt0midGToIvmJWjIQ2iNDW1cvXIHg179SwWQSwukqpluyi5unflL05HussdL67F7u0wZYAU6iFCsWdSw35"
    }
  };
  return dispatch => {
    dispatch(initBalance());
    axios
      .get("token/", config)
      .then(res => dispatch(successBalance(res.data.data)))
      .catch(err => dispatch(failBalance(err.message)));
  };
};
//add Balance
export const addBalance = data => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      PcariCentralAuthorization:
        "E49jMThpWv4lrDGZ0Okt0midGToIvmJWjIQ2iNDW1cvXIHg179SwWQSwukqpluyi5unflL05HussdL67F7u0wZYAU6iFCsWdSw35"
    }
  };
  const formData = new FormData();
  formData.append("amount", data.amount);
  return dispatch => {
    axios
      .post("token/topup", formData, config)
      .then(
        res =>
          (window.location.href =
            res.data.data.data.toyyibpay_bill.redirect_url)
      )
      .catch(err => console.log(err.response));
  };
};
