import axios from "axios";
import { getToken } from "../utils";

import * as actionTypes from "./actionTypes";

//get balance
const initTransactio = () => {
  return {
    type: actionTypes.INIT_GET_TRANSACTION,
    loading_transaction: false
  };
};
const successTransaction = data => {
  return {
    type: actionTypes.SUCCESS_GET_TRANSACTION,
    loading_transaction: false,
    data_transaction: data,
    error_transaction: null
  };
};
const failTransaction = error => {
  return {
    type: actionTypes.FAIL_GET_TRANSACTION,
    loading_transaction: false,
    data_transaction: null,
    error_transaction: error
  };
};

export const getTransaction = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      PcariCentralAuthorization:
        "E49jMThpWv4lrDGZ0Okt0midGToIvmJWjIQ2iNDW1cvXIHg179SwWQSwukqpluyi5unflL05HussdL67F7u0wZYAU6iFCsWdSw35"
    }
  };
  return dispatch => {
    dispatch(initTransactio());
    axios
      .get("token/transaction", config)
      .then(res => dispatch(successTransaction(res.data.data)))
      .catch(err => dispatch(failTransaction(err.message)));
  };
};
