import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  idToken: null,
  user: null,
  error: null,
  loading_user: false,
  keepUser: true
};

const initLogIn = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const succesLogIn = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    idToken: action.idToken,
    user: action.user,
    //
    loading_user: false,
    error_user: null
  };
};

const initProfile = (state, action) => {
  return {
    ...state,
    loading_user: action.loading_user,
  };
};

const successProfile = (state, action) => {
  return {
    ...state,
    loading_user: action.loading_user,
    user: action.user
  };
};

const failLogIn = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_LOG_IN:
      return initLogIn(state, action);
    case actionTypes.SUCCESS_LOG_IN:
      return succesLogIn(state, action);
    case actionTypes.FAIL_LOG_IN:
      return failLogIn(state, action);
    //
    case actionTypes.INIT_GET_PROFILE:
      return initProfile(state, action);
    case actionTypes.SUCCESS_GET_PROFILE:
      return successProfile(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
