import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_promotions: false,
  promotions_data: [],
  //promotions_data: null,
  //
  promotion_types: []
};

const initGetPromotions = (state, action) => {
  return {
    ...state,
    loading_promotions: action.loading_promotions
  };
};

const successGetPromotions = (state, action) => {
  return {
    ...state,
    loading_promotions: action.loading_promotions,
    promotions_data: action.promotions_data
  };
};

const failGetPromotions = (state, action) => {
  return {
    ...state,
    loading_promotions: action.loading_promotions,
    error_pormotions: action.error_pormotions
  };
};
//
const successGetPromotionsTypes = (state, action) => {
  return {
    ...state,
    promotion_types: action.promotion_types
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PROMOTIONS:
      return initGetPromotions(state, action);
    case actionTypes.SUCCESS_GET_PROMOTIONS:
      return successGetPromotions(state, action);
    case actionTypes.FAIL_GET_PROMOTIONS:
      return failGetPromotions(state, action);
    //
    case actionTypes.SUCCESS_GET_PROMOTIONS_TYPES:
      return successGetPromotionsTypes(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
