import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  error: null
};

const initGetCatalogCategories = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetCatalogCategories = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data
  };
};

const failGetCatalogCategories = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CATALOG_CATEGORIES:
      return initGetCatalogCategories(state, action);
    case actionTypes.SUCCESS_GET_CATALOG_CATEGORIES:
      return successGetCatalogCategories(state, action);
    case actionTypes.FAIL_GET_CATALOG_CATEGORIES:
      return failGetCatalogCategories(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
