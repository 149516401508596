import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getToken } from "../utils";

const initGetProducts = () => {
  return {
    type: actionTypes.INIT_GET_PRODUCTS,
    loading: true
  };
};

const successGetProducts = data => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCTS,
    loading: false,
    data: data
  };
};

const failGetProducts = error => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCTS,
    loading: false,
    error: error
  };
};

export const getProducts = () => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };

  return dispatch => {
    dispatch(initGetProducts());
    axios
      .get("product", config)
      .then(response => dispatch(successGetProducts(response.data.data)))
      .catch(err => dispatch(failGetProducts(err.message)));
  };
};

export const deleteProduct = id => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };

  return dispatch => {
    axios
      .delete("product?id=" + id, config)
      .then(response => dispatch(getProducts()))
      .catch(err => console.log(err.message));
  };
};
