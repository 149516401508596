import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  error: null
};

const initGetCatalogAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetCatalogAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data
  };
};

const failGetCatalogAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CATALOG_ATTRIBUTE:
      return initGetCatalogAttribute(state, action);
    case actionTypes.SUCCESS_GET_CATALOG_ATTRIBUTE:
      return successGetCatalogAttribute(state, action);
    case actionTypes.FAIL_GET_CATALOG_ATTRIBUTE:
      return failGetCatalogAttribute(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
