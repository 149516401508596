import axios from "axios";
import { getToken } from "../../utils";
import * as actiontypes from "../actionTypes";

const initGetAttributeGroup = () => {
  return {
    type: actiontypes.INIT_GET_ATTRIBUTE_GROUP,
    loading: true
  };
};

const successGetAttributeGroup = data => {
  return {
    type: actiontypes.SUCCESS_GET_ATTRIBUTE_GROUP,
    loading: false,
    attributeGroup: data
  };
};

const failGetAttributeGroup = error => {
  return {
    type: actiontypes.FAIL_GET_ATTRIBUTE_GROUP,
    loading: false,
    error: error
  };
};

export const getAttributeGroup = () => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };

  return dispatch => {
    initGetAttributeGroup();
    axios
      .get("attribute-group/", config)
      .then(resopnse => {
        const attragroup = resopnse.data.data;
        attragroup.map(attr => dispatch(getAttribute(attr.id)));
        dispatch(successGetAttributeGroup(attragroup));
      })
      .catch(err => dispatch(failGetAttributeGroup(err.message)));
  };
};

// #########################################################Single Attribute :

const initGetAttribute = key => {
  return {
    type: actiontypes.INIT_GET_ATTRIBUTE,
    loading: true,
    key: key
  };
};

const successGetAttribute = (key, data) => {
  return {
    type: actiontypes.SUCCESS_GET_ATTRIBUTE,
    key: key,
    loading: false,
    data: data
  };
};

const failGetAttribute = (key, error) => {
  return {
    type: actiontypes.FAIL_GET_ATTRIBUTE,
    key: key,
    loading: false,
    error: error
  };
};

export const getAttribute = id => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };
  return dispatch => {
    initGetAttribute(id);
    axios
      .get(`attribute-group/edit/${id}`, config)
      .then(response => {
        const key = response.data.data.id.toString();
        const data = response.data.data.attribute;
        // console.log(key, data)
        dispatch(successGetAttribute(key, data));
      })
      .catch(err => dispatch(failGetAttribute(id, err.message)));
  };
};
