import axios from "axios";
import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";

const initProductOption = () => {
  return {
    type: actionTypes.INIT_GET_OPTION,
    loading: true
  };
};

const successProductVisibility = data => {
  return {
    type: actionTypes.SUCCESS_GET_VISIBILITY,
    productVisibility: data,
    loading: false
  };
};
const successProductType = data => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCT_TYPE,
    productType: data,
    loading: false
  };
};

const failProductOption = error => {
  return {
    type: actionTypes.FAIL_GET_OPTION,
    loading: false,
    error: error
  };
};

export const getProductVisibility = () => {
  return dispatch => {
    dispatch(initProductOption());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken
      }
    };

    axios
      .get("product-visibility", config)
      .then(response => dispatch(successProductVisibility(response.data.data)))

      .catch(err => dispatch(failProductOption(err.message)));
  };
};

export const getProductType = () => {
  return dispatch => {
    dispatch(initProductOption());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken
      }
    };

    axios
      .get("product-type", config)
      .then(response => dispatch(successProductType(response.data.data)))

      .catch(err => dispatch(failProductOption(err.message)));
  };
};
