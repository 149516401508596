import axios from "axios";
import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";

const initGetCatalogCategoryProducts = () => {
  return {
    type: actionTypes.INIT_GET_TAG_CATALOG_CATEGORY_PRODUCTS,
    loading: true
  };
};

const successGetCatalogCategoryProducts = data => {
  return {
    type: actionTypes.SUCCESS_GET_TAG_CATALOG_CATEGORY_PRODUCTS,
    loading: false,
    data: data
  };
};

const failGetCatalogCategoryProducts = error => {
  return {
    type: actionTypes.FAIL_GET_TAG_CATALOG_CATEGORY_PRODUCTS,
    loading: false,
    error: error
  };
};
// const resetGetCatalogCategoryProducts = (error) => {
//     return {
//         type: actionTypes.RESET_GET_TAG_CATALOG_CATEGORY_PRODUCTS,
//     }
// };
// RESET_GET_TAG_CATALOG_CATEGORY_PRODUCTS
export const getCatalogCategoryProducts = id => {
  const idToken = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + idToken
    }
  };

  return dispatch => {
    dispatch(initGetCatalogCategoryProducts());
    axios
      .get(`vendor/category/product/${id}`, config)
      .then(response => {
        dispatch(successGetCatalogCategoryProducts(response.data.data));
      })
      .catch(err => dispatch(failGetCatalogCategoryProducts(err.message)));
  };
};

//post :

export const submitCategoryProducts = data => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  const formData = new FormData();

  Object.entries(data).map(([key, val]) => formData.append(key, val));

  return dispatch => {
    dispatch({
      type: actionTypes.INIT_POST_TAG_CATALOG_CATEGORY_PRODUCTS,
      submitting: true
    });
    axios
      .post("vendor/category/product", formData, config)
      .then(responst =>
        dispatch({
          type: actionTypes.SUCCESS_POST_TAG_CATALOG_CATEGORY_PRODUCTS,
          success: true,
          submitting: false
        })
      )
      .catch(err => {
        console.log(err.response);
        dispatch({
          type: actionTypes.FAIL_POST_TAG_CATALOG_CATEGORY_PRODUCTS,
          error: err.message,
          submitting: false
        });
      });
  };
};
