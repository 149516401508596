import * as actionTypes from "../actions/actionTypes";

const initState = {
  shippers_loading: false,
  shippers_data: [],
  shippers_error: null,
};

const initShippers = (state, action) => {
  return {
    ...state,
    shippers_loading: action.shippers_loading,
  };
};
const successShippers = (state, action) => {
  return {
    ...state,
    shippers_loading: action.shippers_loading,
    shippers_data: action.shippers_data,
    shippers_error: action.shippers_error,
  };
};
const failShippers = (state, action) => {
  return {
    ...state,
    shippers_loading: action.shippers_loading,
    shippers_data: action.shippers_data,
    shippers_error: action.shippers_error,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_SHIPPERS:
      return initShippers(state, action);
    case actionTypes.SUCCESS_GET_SHIPPERS:
      return successShippers(state, action);
    case actionTypes.FAIL_GET_SHIPPERS:
      return failShippers(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
