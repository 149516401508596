import * as actionTypes from "../../actions/actionTypes";

const initState = {
  getting: false,
  data: null,
  detailError: null,
  //post
  loading: false,
  success: null,
  error: null
};

export const initGetDetail = (state, action) => {
  return {
    type: action.type,
    getting: action.getting
  };
};
export const successGetDetail = (state, action) => {
  return {
    type: action.type,
    getting: action.getting,
    data: action.data
  };
};
export const failGetDetail = (state, action) => {
  return {
    type: action.type,
    getting: action.getting,
    detailError: action.detailError
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CATALOG_ATTRIBUTE_DETAILS:
      return initGetDetail(state, action);
    case actionTypes.SUCCESS_GET_CATALOG_ATTRIBUTE_DETAILS:
      return successGetDetail(state, action);
    case actionTypes.FAIL_GET_CATALOG_ATTRIBUTE_DETAILS:
      return failGetDetail(state, action);
    case actionTypes.RESET_ATTRIBUTE_DETAILS:
      return { ...state };
    //post
    case actionTypes.INIT_POST_CATALOG_ATTRIBUTE:
      return { loading: action.loading };
    case actionTypes.SUCCESS_POST_CATALOG_ATTRIBUTE:
      return { loading: action.loading, success: action.success };
    case actionTypes.FAIL_POST_CATALOG_ATTRIBUTE:
      return { loading: action.loading, error: action.error };
    case actionTypes.RESET_POST_CATALOG:
      return { ...initState };
    default:
      return { ...state };
  }
};

export default Reducer;
