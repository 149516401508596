import React, { useEffect } from "react";
import { LayoutSplashScreen } from "../../layout";
import { Container, Row, Col } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { getDashoard } from "../../../redux/actions/index";
import salesIcon from "../../_assets/media/sales-h1.png";
import { useHistory } from "react-router";

export function DashboardVendor() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading_dashboard, status } = useSelector((state) => state.dashboard);
  // error_dashboard,
  // sales,
  // product,
  // category,

  useEffect(() => {
    dispatch(getDashoard());
  }, [dispatch]);

  return loading_dashboard ? (
    <LayoutSplashScreen visible={true} />
  ) : (
    <Container>
      <Row className="mt-4">
        <Col>
          <h1
            className="fw-bold d-flex align-items-end"
            style={{ color: "#363030", fontSize: "3rem" }}
          >
            <img
              width="65px"
              height="65px"
              src={salesIcon}
              alt="sales"
              className="mr-2"
            />
            Order Statistics
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="my-5 sale ">
          <div
            className="sale-body sale-total"
            onClick={() => {
              history.push({
                pathname: "/orders",
                state: {
                  // location state
                  activeKey: "orders",
                },
              });
            }}
          >
            <h1>{status ? status.total : ""}</h1>
            <h2>Total Orders</h2>
          </div>
        </Col>
        <Col md={6} className="my-5 sale ">
          <div
            className="sale-body sale-processin"
            onClick={() => {
              history.push({
                pathname: "/orders",
                state: {
                  // location state
                  activeKey: "shipping",
                },
              });
            }}
          >
            <h1>{status ? status.shipped : ""}</h1>
            <h2>Processing</h2>
          </div>
        </Col>
        <Col
          md={6}
          className="my-5 sale "
          onClick={() => {
            history.push({
              pathname: "/orders",
              state: {
                // location state
                activeKey: "shipping",
              },
            });
          }}
        >
          <div className="sale-body sale-pending">
            <h1>
              {status ? +status.pending_shipping + +status.parcel_pending : ""}
            </h1>
            <h2>Pending</h2>
          </div>
        </Col>
        <Col
          md={6}
          className="my-5 sale"
          onClick={() => {
            history.push({
              pathname: "/orders",
              state: {
                // location state
                activeKey: "parcel",
              },
            });
          }}
        >
          <div className="sale-body sale-complete">
            <h1>{status ? status.complete : ""}</h1>
            <h2>Complete</h2>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
