import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getToken } from "../utils";

const initGetOrders = () => {
  return {
    type: actionTypes.INIT_GET_ORDERS,
    loading_orders: true,
  };
};

const successGetOrders = (data, key) => {
  return {
    type: actionTypes.SUCCESS_GET_ORDERS,
    orders_data: data,
    key: key,
    loading_orders: false,
  };
};

// const failGetOrders = error => {
//   return {
//     type: actionTypes.FAIL_GET_ORDERS,
//     loading_orders: false,
//     error_orders: error
//   };
// };

export const getOrders = () => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  return (dispatch) => {
    dispatch(initGetOrders());
    axios
      .get("sales/order?status=incoming_order", config)
      .then((response) =>
        dispatch(successGetOrders(response.data.data, "orders"))
      )
      .catch((err) => {
        console.log("###response", err.response, err);
      });
    axios
      .get("sales/order?status=to_process", config)
      .then((response) =>
        dispatch(successGetOrders(response.data.data, "shipping"))
      )
      .catch((err) => {
        console.log("###response", err.response);
      });
    axios
      .get("sales/order?status=processing", config)
      .then((response) =>
        dispatch(successGetOrders(response.data.data, "drop"))
      )
      .catch((err) => {
        console.log("###response", err.response);
      });
    axios
      .get("sales/order?status=completed", config)
      .then((response) =>
        dispatch(successGetOrders(response.data.data, "parcel"))
      )
      .catch((err) => {
        console.log("###response", err.response);
      });
    axios
      .get("sales/order?status=cancel_refund", config)
      .then((response) =>
        dispatch(successGetOrders(response.data.data, "cancel"))
      )
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

//
const initGetOrder = () => {
  return {
    type: actionTypes.INIT_GET_ORDER,
    loading_order: true,
  };
};

const successGetOrder = (data, cancelData = false) => {
  if (cancelData) {
    const sales = JSON.parse(JSON.stringify(data.sales));
    delete sales.id;
    delete data.sales;
    data = {
      ...data,
      ...sales,
    };
  }

  return {
    type: actionTypes.SUCCESS_GET_ORDER,
    order_data: data,
    loading_order: false,
    error_order: null,
  };
};

const failGetOrder = (error) => {
  return {
    type: actionTypes.FAIL_GET_ORDER,
    loading_order: false,
    error_order: error,
    order_data: null,
  };
};
export const resetOrder = (error) => {
  return {
    type: actionTypes.RESET_ORDER,
  };
};

export const getOrder = (orderId, cancelUrl = false) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  let url = `sales/order/${orderId}`;
  if (cancelUrl) url = `sales/order/cancel/view/${orderId}`;
  return (dispatch) => {
    dispatch(initGetOrder());
    axios
      .get(url, config)
      .then((response) =>
        dispatch(successGetOrder(response.data.data, cancelUrl))
      )
      .catch((err) => {
        console.log("###response", err.response, err);
        dispatch(failGetOrder(err));
      });
  };
};

//ACCEPT ORDER
export const accpetOrder = (data, addToast, redirect) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };

  const formData = new FormData();
  Object.entries(data).map(([key, val]) => formData.append(key, val));

  let baseUrl = axios.defaults.baseURL;
  baseUrl = baseUrl.replace("1", "2") + "sales/order/accept";

  return (dispatch) => {
    dispatch(resetOrder());
    dispatch(initGetOrder());
    axios
      .post(baseUrl, formData, config)
      .then((response) => {
        dispatch(getOrder(data.sales_order_id));
        redirect({
          pathname: "/order/detail",
          state: {
            activeTab: "shipping",
            row: { id: data.sales_order_id },
          },
        });
        addToast("Order Accepted", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        dispatch(failGetOrder(err));
        redirect({
          pathname: "/orders",
          state: {
            activeKey: "orders",
          },
        });
        addToast(
          err?.response?.data?.messages ??
            "Ooops somthing went wrong please try again later",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        console.log("###response", err.response);
      });
  };
};

//DROPOFF ORDER
export const dropoffOrder = (data, addToast, redirect) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  return (dispatch) => {
    dispatch(resetOrder());
    dispatch(initGetOrder());
    axios
      .put("sales/order/shipping/dropoff", data, config)
      .then((response) => {
        dispatch(getOrder(data.sales_order_id));
        redirect({
          pathname: "/order/detail",
          state: {
            activeTab: "drop",
            row: { id: data.sales_order_id },
          },
        });
        addToast("Order Dropped Off", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        dispatch(failGetOrder(err));
        redirect({
          pathname: "/orders",
          state: {
            activeKey: "orders",
          },
        });
        addToast(
          err?.response?.data?.messages ??
            "Ooops somthing went wrong please try again later",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        console.log("###response", err.response);
      });
  };
};

export const updateOrderStatus = (data, mode) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  const formData = new FormData();
  Object.entries(data).map(([key, val]) => formData.append(key, val));

  let url = "sales/order/order/update";
  if (mode === "shipping") url = "sales/order/order/shipping";
  return (dispatch) => {
    axios
      .post(url, formData, config)
      .then((response) => dispatch(getOrders()))
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

export const updateShippingCompelte = (data) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  const formData = new FormData();
  Object.entries(data).map(([key, val]) => formData.append(key, val));

  return (dispatch) => {
    axios
      .post("sales/order/order/shipping/complete", formData, config)
      .then((response) => dispatch(getOrders()))
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

//Shipping vendors:
const initGetShippingVendors = () => {
  return {
    type: actionTypes.INIT_GET_SHIPPING_VENDORS,
    loading_shipping_vendors: true,
  };
};

const successGetShippingVendors = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_SHIPPING_VENDORS,
    data_shipping_vendors: data,
    loading_shipping_vendors: false,
  };
};

// const failGetShippingVendors = error => {
//   return {
//     type: actionTypes.FAIL_GET_SHIPPING_VENDORS,
//     loading_shipping_vendors: false,
//     error_shipping_vendors: error
//   };
// };

export const getShippingVendors = (data) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  const formData = new FormData();
  Object.entries(data).map(([key, val]) => formData.append(key, val));
  return (dispatch) => {
    dispatch(initGetShippingVendors());
    axios
      .post("sales/order/shipping/checkrate", formData, config)
      .then((response) =>
        dispatch(successGetShippingVendors(response.data.data))
      )
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

export const getOrderAWB = (id, addToast, tracking) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  return (dispatch) => {
    axios
      .get("sales/order/awb/" + id, config)
      .then((response) => {
        const awb = response.data.data;
        if (awb.awb_id_link && awb.awb_id_link !== "") {
          let link = tracking ? awb.tracking_url : awb.awb_id_link;
          const element = document.createElement("a");
          element.href = link;
          element.target = "blank";
          element.click();
        } else {
          addToast("Awb not available. Please try again later", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        console.log("###response", err.response);
        let message = "";

        if (err.response && err.response.data)
          message =
            err.response.data.messages ??
            "Oops... something went wrong. Can't download Airway Bill";

        addToast(message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
};

export const getOrderInvoice = (id) => {
  const idToken = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
    responseType: "blob",
  };
  return (dispatch) => {
    axios
      .get("sales/order/" + id + "/invoice", config)
      .then((response) => {
        const element = document.createElement("a");
        element.href = URL.createObjectURL(response.data);
        element.download = `invoice-${id}`;
        element.click();
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
