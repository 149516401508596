import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { findPostcode } from "malaysia-postcodes";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillCloseCircle,
} from "react-icons/ai";
import {
  getAuth,
  updatePassword,
  updateProfile as updateAuthPorfile,
  reauthenticateWithCredential,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import classes from "./RequiredForm.module.css";
import { logOut, UpdateProfile } from "../../../redux/actions";
import { useToasts } from "react-toast-notifications";

export default function RequiredForm() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { addToast } = useToasts();

  const [icon, setIcon] = useState({});
  const [banner, setBanner] = useState({});
  const [multiForm, setMultiForm] = useState(1);
  const [fristStepValid, setFristStepValid] = useState("nutral");
  const [secondeStepValid, setSecondeStepValid] = useState("nutral");
  const [showPassword, setShowPassword] = useState(false);

  const controllerBackground = {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#1A8917",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
  };

  const initprofileForm = {
    name: "",
    shop_name: "",
    email: localStorage.getItem("email") ?? "",
    bindPassword: false,
    password: "",
    passwordConfirm: "",
    company_logo: {},
    company_banner: {},
  };

  const initCompanyForm = {
    company_name: "",
    company_register_no: "",
    company_office_no: "",
    company_phone_no: "",
    company_email: "",
    company_latitude: "",
    company_longitude: "",
    account_no: "",
    account_name: "",
    bank_name: "",
  };

  const initAddressForm = {
    company_address_1: "",
    company_address_2: "",
    company_address_postcode: "",
    company_address_city: "",
    company_address_state: "",
    company_address_country: "Malaysia",
  };

  const profileFormik = useFormik({
    initialValues: initprofileForm,
    validationSchema: yup.object().shape({
      name: yup.string().required("name is required"),
      shop_name: yup.string().required("required"),
      email: yup
        .string()
        .required("required")
        .email("invalid email"),
      password: yup.string().when("bindPassword", {
        is: true,
        then: yup.string().required("required"),
      }),
      passwordConfirm: yup.string().when("password", {
        is: (val) => val,
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
      }),
    }),
    onSubmit: () => {
      setMultiForm(2);
      setFristStepValid("valid");
    },
  });

  const companyFormik = useFormik({
    initialValues: initCompanyForm,
    validationSchema: yup.object().shape({
      company_name: yup.string().required("required"),
      company_office_no: yup
        .string()
        .required("required")
        .min(10, "invalid phone number")
        .max(13, "invalid phone number "),
      company_phone_no: yup
        .string()
        .required("required")
        .min(10, "invalid phone number")
        .max(13, "invalid phone number "),
      company_email: yup
        .string()
        .required("required")
        .email("invalid email"),
    }),
    onSubmit: () => {
      setSecondeStepValid("valid");
      setMultiForm(3);
    },
  });

  const addressFormik = useFormik({
    initialValues: initAddressForm,
    validationSchema: yup.object().shape({
      company_address_1: yup.string().required("required"),
      company_address_2: yup.string().required("required"),
      company_address_postcode: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .required("required"),
      company_address_city: yup.string().required("required"),
      company_address_state: yup.string().required("required"),
      company_address_country: yup.string().required("required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
        ...profileFormik.values,
        ...companyFormik.values,
        _method: "PUT",
        active: 1,
      };
      setSubmitting(true);
      const auth = getAuth();
      const currentUser = auth.currentUser;

      updatePassword(currentUser, profileFormik.values.password)
        .then(() => {
          dispatch(UpdateProfile(data, addToast));
          updateAuthPorfile(currentUser, {
            displayName: data.name,
          });
        })
        .catch((err) => {
          console.log(err);
          addToast("Please Sign in again to continue!", {
            appearance: "warning",
            autoDismiss: false,
          });
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
            .then((result) => {
              const credential = GoogleAuthProvider.credentialFromResult(
                result
              );
              return credential;
            })
            .then((credential) => {
              reauthenticateWithCredential(currentUser, credential).then(() => {
                dispatch(UpdateProfile(data, addToast));
                updateAuthPorfile(currentUser, {
                  displayName: data.name,
                })
              });
            })
            .catch((error) => {
              dispatch(logOut());
              addToast("Something went wrong..", {
                appearance: "error",
                autoDismiss: false,
              });
            });
        })
        .finally(() => setSubmitting(false));
    },
  });

  const fileChangeHandler = (event, setter, key) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      const ImgTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
      const validType = ImgTypes.some((type) => file.type === type);
      if (file.size < 6 * Math.pow(10, 6) && validType) {
        reader.onload = () => {
          setter({
            src: reader.result,
            err: null,
          });
        };
        profileFormik.setFieldValue(key, event.target.files[0]);
      } else {
        setter({ src: null, err: "image should be less than 6MB" });
        profileFormik.setFieldValue(key, {});
      }
    } else {
      setter({ src: null, err: "required" });
      profileFormik.setFieldValue(key, {});
    }
  };

  const setProfileValues = profileFormik.setValues;
  const setCompanyValues = companyFormik.setValues;
  const setAddresValues = addressFormik.setValues;

  useEffect(() => {
    if (user) {
      let bindPassword = user.providerData.includes("password") ? false : true;
      setProfileValues({
        password: "",
        passwordConfirm: "",
        bindPassword,
        name: user?.name ?? "",
        shop_name: user?.shop_name ?? "",
        email: user?.email ?? "",
      });
      setCompanyValues({
        company_name: user?.company_name ?? "",
        company_register_no: user?.company_register_no ?? "",
        company_office_no: user?.company_office_no ?? "",
        company_phone_no: user?.company_phone_no ?? "",
        company_email: user?.company_email ?? "",
        company_logo: user?.company_logo ?? {},
        company_banner: user?.company_banner ?? {},
        company_latitude: user?.company_latitude ?? "",
        company_longitude: user?.company_longitude ?? "",
        account_no: user?.account_no ?? "",
        account_name: user?.account_name ?? "",
        bank_name: user?.bank_name ?? "",
      });
      setAddresValues({
        company_address_1: user?.company_address_1 ?? "",
        company_address_2: user?.company_address_2 ?? "",
        company_address_postcode: user?.company_address_postcode ?? "",
        company_address_city: user?.company_address_city ?? "",
        company_address_state: user?.company_address_state ?? "",
        company_address_country: user?.company_address_country ?? "Malaysia",
      });
    }
  }, [user, setProfileValues, setCompanyValues, setAddresValues]);

  return (
    <div className="py-5">
      <Container
        className="py-5 px-lg-4 my-5 bg-white shadow-sm rounded-2 position-relative"
        style={{ minHeight: "80vh" }}
      >
        <Row className="px-lg-5">
          <Col className="my-5">
            <h1>Required Information</h1>
            <h6 className="mt-4">
              Please fill in the required information to proceed to your
              dashboard
            </h6>
            <p className="text-muted">
              <small>required information marked with</small>
              <span className="text-danger"> *</span>
            </p>
          </Col>
          <Button
            type="button"
            variant="outline-primary"
            size="sm"
            onClick={(e) => dispatch(logOut())}
            className="mt-2 mr-2 position-absolute top-0 right-0"
          >
            Log out
          </Button>
        </Row>

        <Row className="px-lg-5">
          <Col>
            <Row className="mb-5">
              <Col className="mb-4">
                <div className="text-center my-2">
                  <small
                    className={classes.profile}
                    style={{
                      ...controllerBackground,
                      background:
                        fristStepValid === "valid"
                          ? "#1A8917"
                          : fristStepValid === "invalid"
                          ? "red"
                          : "#F4F4F4",
                      color: fristStepValid === "nutral" ? "black" : "white",
                    }}
                    onClick={() => setMultiForm(1)}
                  >
                    1
                  </small>
                  <sup
                    className={`fw-bold d-inline d-md-none ${
                      multiForm >= 2 ? "text-success" : "text-gray-l"
                    }`}
                  >
                    ___________
                  </sup>
                  <sup
                    className={`fw-bold d-none d-md-inline ${
                      multiForm >= 2 ? "text-success" : "text-gray-l"
                    }`}
                  >
                    ______________________
                  </sup>
                  <small
                    className={classes.company}
                    onClick={() =>
                      profileFormik.isValid
                        ? setMultiForm(2)
                        : setFristStepValid("invalid")
                    }
                    style={{
                      ...controllerBackground,
                      background:
                        secondeStepValid === "valid" && multiForm >= 2
                          ? "#1A8917"
                          : secondeStepValid === "invalid" && multiForm >= 2
                          ? "red"
                          : "#F4F4F4",
                      color:
                        secondeStepValid === "valid" && multiForm >= 2
                          ? "white"
                          : secondeStepValid === "invalid" && multiForm >= 2
                          ? "white"
                          : "black",
                    }}
                  >
                    2
                  </small>
                  <sup
                    className={`fw-bold d-inline d-md-none ${
                      multiForm > 2 ? "text-success" : "text-gray-l"
                    }`}
                  >
                    ___________
                  </sup>
                  <sup
                    className={`fw-bold d-none d-md-inline ${
                      multiForm > 2 ? "text-success" : "text-gray-l"
                    }`}
                  >
                    ______________________
                  </sup>
                  <small
                    className={classes.address}
                    onClick={() =>
                      companyFormik.isValid
                        ? setMultiForm(3)
                        : setSecondeStepValid("invalid")
                    }
                    style={{
                      ...controllerBackground,
                      background: "#F4F4F4",
                      color: "black",
                    }}
                  >
                    3
                  </small>
                </div>
              </Col>
            </Row>
            {multiForm === 1 && (
              <Form onSubmit={profileFormik.handleSubmit}>
                <Row>
                  <Col>
                    <h2 className="my-5  py-2">1. Profile Information</h2>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={profileFormik.values.name}
                      isValid={
                        profileFormik.touched.name && !profileFormik.errors.name
                      }
                      isInvalid={
                        profileFormik.touched.name && profileFormik.errors.name
                      }
                      {...profileFormik.getFieldProps("name")}
                    />
                    <Form.Control.Feedback type="invalid">
                      name required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Shop Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={profileFormik.values.shop_name}
                      isValid={
                        profileFormik.touched.shop_name &&
                        !profileFormik.errors.shop_name
                      }
                      isInvalid={
                        profileFormik.touched.shop_name &&
                        profileFormik.errors.shop_name
                      }
                      {...profileFormik.getFieldProps("shop_name")}
                    />
                    <Form.Control.Feedback type="invalid">
                      required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {user?.providerData.includes("password") && (
                    <Form.Group as={Col} md="4">
                      <Form.Label>
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={profileFormik.values.email}
                        isValid={
                          profileFormik.touched.email &&
                          !profileFormik.errors.email
                        }
                        isInvalid={
                          profileFormik.touched.email &&
                          profileFormik.errors.email
                        }
                        {...profileFormik.getFieldProps("email")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {profileFormik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Row>

                {!user?.providerData.includes("password") && (
                  <Row>
                    <Form.Group as={Col} md="4">
                      <Form.Label>
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={profileFormik.values.email}
                        isValid={
                          profileFormik.touched.email &&
                          !profileFormik.errors.email
                        }
                        isInvalid={
                          profileFormik.touched.email &&
                          profileFormik.errors.email
                        }
                        {...profileFormik.getFieldProps("email")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {profileFormik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>
                        Password <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={profileFormik.values.password}
                        isValid={
                          profileFormik.touched.password &&
                          !profileFormik.errors.password
                        }
                        isInvalid={
                          profileFormik.touched.password &&
                          profileFormik.errors.password
                        }
                        {...profileFormik.getFieldProps("password")}
                      />
                      <span
                        className="position-absolute"
                        style={{
                          right: "3rem",
                          top: "2.5rem",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </span>
                      <span
                        className="position-absolute"
                        style={{
                          right: "4.7rem",
                          top: "2.9rem",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        onClick={() =>
                          profileFormik.setFieldValue("password", "")
                        }
                      >
                        <AiFillCloseCircle />
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {profileFormik.errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="passwordConfirm"
                        value={profileFormik.values.passwordConfirm}
                        isValid={
                          profileFormik.touched.passwordConfirm &&
                          !profileFormik.errors.passwordConfirm
                        }
                        isInvalid={
                          profileFormik.touched.passwordConfirm &&
                          profileFormik.errors.passwordConfirm
                        }
                        {...profileFormik.getFieldProps("passwordConfirm")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {profileFormik.errors.passwordConfirm}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                )}
                {/* ########################            Media        ################################*/}
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Company Logo</Form.Label>
                    <br />
                    {icon && icon.src ? (
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #f0f0f0",
                        }}
                        className="mb-4"
                        src={icon.src}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #f0f0f0",
                        }}
                        className="mb-4"
                        src={toAbsoluteUrl("/media/no-image-placeholder.png")}
                        alt=""
                      />
                    )}
                    <br />
                    <Form.Label
                      htmlFor="company_logo"
                      className={
                        icon && icon.src
                          ? "btn btn-outline-success"
                          : icon && icon.err
                          ? "btn btn-outline-danger"
                          : "btn btn-outline-secondary"
                      }
                    >
                      Upload
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="company_logo"
                      id="company_logo"
                      style={{ display: "none" }}
                      isValid={icon ? icon.src : null}
                      isInvalid={icon ? icon.err : null}
                      onChange={(event) =>
                        fileChangeHandler(event, setIcon, "company_logo")
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {icon ? icon.err : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Company Banner</Form.Label>
                    <br />
                    {banner && banner.src ? (
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #f0f0f0",
                        }}
                        className="mb-4"
                        src={banner.src}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #f0f0f0",
                        }}
                        className="mb-4"
                        src={toAbsoluteUrl("/media/no-image-placeholder.png")}
                        alt=""
                      />
                    )}
                    <br />
                    <Form.Label
                      htmlFor="company_banner"
                      className={
                        banner && banner.src
                          ? "btn btn-outline-success"
                          : banner && banner.err
                          ? "btn btn-outline-danger"
                          : "btn btn-outline-secondary"
                      }
                    >
                      Upload
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="company_banner"
                      id="company_banner"
                      style={{ display: "none" }}
                      isValid={banner ? banner.src : null}
                      isInvalid={banner ? banner.err : null}
                      onChange={(event) =>
                        fileChangeHandler(event, setBanner, "company_banner")
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {banner ? banner.err : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      variant={
                        profileFormik.errors.name && profileFormik.errors.email
                          ? "danger"
                          : "primary"
                      }
                    >
                      Next Step
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
            {multiForm === 2 && (
              <Form onSubmit={companyFormik.handleSubmit}>
                <Row>
                  <Col>
                    <h2 className="my-5  py-2">2. Company Information</h2>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Company Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="company_name"
                      value={companyFormik.values.company_name}
                      isValid={
                        companyFormik.touched.company_name &&
                        !companyFormik.errors.company_name
                      }
                      isInvalid={
                        companyFormik.touched.company_name &&
                        companyFormik.errors.company_name
                      }
                      {...companyFormik.getFieldProps("company_name")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {companyFormik.errors.company_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Company Email <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="company_email"
                      value={companyFormik.values.company_email}
                      isValid={
                        companyFormik.touched.company_email &&
                        !companyFormik.errors.company_email
                      }
                      isInvalid={
                        companyFormik.touched.company_email &&
                        companyFormik.errors.company_email
                      }
                      {...companyFormik.getFieldProps("company_email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {companyFormik.errors.company_email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Company Register Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="company_register_no"
                      value={companyFormik.values.company_register_no}
                      {...companyFormik.getFieldProps("company_register_no")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {companyFormik.errors.company_register_no}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Company Office Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <InputMask
                      className={`form-control  ${
                        companyFormik.errors.company_office_no &&
                        companyFormik.touched.company_office_no
                          ? "is-invalid"
                          : ""
                      }`}
                      mask="+60 99 999 9999 999\ "
                      maskChar=" "
                      value={companyFormik.values.company_office_no}
                      onBlur={(e) =>
                        companyFormik.setFieldTouched("company_office_no")
                      }
                      onChange={(e) =>
                        companyFormik.setFieldValue(
                          "company_office_no",
                          e.target.value.replace(/\s/g, "")
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {companyFormik.errors.company_office_no}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>
                      Company Phone Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <InputMask
                      className={`form-control  ${
                        companyFormik.errors.company_phone_no &&
                        companyFormik.touched.company_phone_no
                          ? "is-invalid"
                          : ""
                      }`}
                      mask="+60 99 999 9999 999\ "
                      maskChar=" "
                      value={companyFormik.values.company_phone_no}
                      onBlur={(e) =>
                        companyFormik.setFieldTouched("company_phone_no")
                      }
                      onChange={(e) =>
                        companyFormik.setFieldValue(
                          "company_phone_no",
                          e.target.value.replace(/\s/g, "")
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {companyFormik.errors.company_phone_no}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Bank Account Number </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_no"
                      value={companyFormik.values.account_no}
                      {...companyFormik.getFieldProps("account_no")}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Account Holder </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_name"
                      value={companyFormik.values.account_name}
                      {...companyFormik.getFieldProps("account_name")}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_name"
                      value={companyFormik.values.bank_name}
                      {...companyFormik.getFieldProps("bank_name")}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="button"
                      variant="primary"
                      // onClick={(e) => multiFormControllerHandler(1)}
                      onClick={(e) => setMultiForm(1)}
                    >
                      Previous Step
                    </Button>
                    <Button
                      type="submit"
                      variant={
                        companyFormik.errors.company_name &&
                        companyFormik.touched.company_name &&
                        companyFormik.touched.company_email &&
                        companyFormik.errors.company_email
                          ? "danger"
                          : "primary"
                      }
                      className="ml-3"
                      // onClick={(e) => multiFormControllerHandler(3)}
                    >
                      Next Step
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
            {multiForm === 3 && (
              <Form onSubmit={addressFormik.handleSubmit}>
                <Row>
                  <Col>
                    <h2 className="my-5  py-2">3. Address</h2>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Label>
                      Company Address <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="text"
                      name="company_address_1"
                      placeholder={"Line 1"}
                      value={addressFormik.values.company_address_1}
                      isValid={
                        addressFormik.touched.company_address_1 &&
                        !addressFormik.errors.company_address_1
                      }
                      isInvalid={
                        addressFormik.touched.company_address_1 &&
                        addressFormik.errors.company_address_1
                      }
                      {...addressFormik.getFieldProps("company_address_1")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_1}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="text"
                      name="company_address_2"
                      placeholder={"Line 2"}
                      value={addressFormik.values.company_address_2}
                      isValid={
                        addressFormik.touched.company_address_2 &&
                        !addressFormik.errors.company_address_2
                      }
                      isInvalid={
                        addressFormik.touched.company_address_2 &&
                        addressFormik.errors.company_address_2
                      }
                      {...addressFormik.getFieldProps("company_address_2")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_2}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="number"
                      name="company_address_postcode"
                      placeholder={"POST CODE"}
                      value={addressFormik.values.company_address_postcode}
                      isValid={
                        addressFormik.touched.company_address_postcode &&
                        !addressFormik.errors.company_address_postcode
                      }
                      isInvalid={
                        addressFormik.touched.company_address_postcode &&
                        addressFormik.errors.company_address_postcode
                      }
                      onBlur={(e) => {
                        const location = findPostcode(e.target.value);
                        if (location && location.found) {
                          addressFormik.setFieldValue(
                            "company_address_city",
                            location.city
                          );
                          addressFormik.setFieldValue(
                            "company_address_state",
                            location.state
                          );
                        } else {
                          addressFormik.setFieldError(
                            "company_address_postcode",
                            "invalid postcode"
                          );
                        }
                      }}
                      onFocus={(e) =>
                        addressFormik.setFieldTouched(
                          "company_address_postcode"
                        )
                      }
                      onChange={(e) =>
                        addressFormik.setFieldValue(
                          "company_address_postcode",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_postcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="text"
                      name="company_address_city"
                      placeholder={"City"}
                      value={addressFormik.values.company_address_city}
                      isValid={
                        addressFormik.touched.company_address_city &&
                        !addressFormik.errors.company_address_city
                      }
                      isInvalid={
                        addressFormik.touched.company_address_city &&
                        addressFormik.errors.company_address_city
                      }
                      {...addressFormik.getFieldProps("company_address_city")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="text"
                      name="company_address_state"
                      placeholder={"State"}
                      value={addressFormik.values.company_address_state}
                      isValid={
                        addressFormik.touched.company_address_state &&
                        !addressFormik.errors.company_address_state
                      }
                      isInvalid={
                        addressFormik.touched.company_address_state &&
                        addressFormik.errors.company_address_state
                      }
                      {...addressFormik.getFieldProps("company_address_state")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Control
                      type="text"
                      name="company_address_country"
                      placeholder={"Country"}
                      value={addressFormik.values.company_address_country}
                      isValid={
                        addressFormik.touched.company_address_country &&
                        !addressFormik.errors.company_address_country
                      }
                      isInvalid={
                        addressFormik.touched.company_address_country &&
                        addressFormik.errors.company_address_country
                      }
                      {...addressFormik.getFieldProps(
                        "company_address_country"
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {addressFormik.errors.company_address_country}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="button"
                      variant="primary"
                      onClick={(e) => setMultiForm(2)}
                    >
                      Previous Step
                    </Button>
                    <Button
                      type="Submit"
                      variant={
                        addressFormik.errors.company_name &&
                        addressFormik.touched.company_name &&
                        addressFormik.touched.company_email &&
                        addressFormik.errors.company_email
                          ? "danger"
                          : "primary"
                      }
                      className="ml-3"
                      disabled={addressFormik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
