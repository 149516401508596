import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiFillCloseCircle,
} from "react-icons/ai";
// import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { GoogleLoginButton } from "react-social-login-buttons"; //FacebookLoginButton
import { Row, Col } from "react-bootstrap";

import { logIn, failLogIn, googleAuth } from "../../../redux/actions/index";

const initialValues = {
  email: localStorage.getItem("email") ?? "",
  password: "",
  keepMe: false,
};

function Login(props) {
  const { intl } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, error } = useSelector((state) => state.auth);
  const { setIsAuthenticated } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    keepMe: Yup.boolean(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      enableLoading();
      const data = values;
      dispatch(logIn(data, setIsAuthenticated));
    },
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    dispatch(failLogIn(null));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      disableLoading();
      setIsAuthenticated(true);
    }
  }, [user, setIsAuthenticated]);

  useEffect(() => {
    if (error) {
      disableLoading();
      setSubmitting(false);
    }
  }, [error, setSubmitting]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your Email and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onFocus={() => {
          if (error) dispatch(failLogIn(null));
        }}
      >
        {error ? (
          error.startsWith("Your account") ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{error}</div>
            </div>
          ) : (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                <FormattedMessage id="AUTH.VALIDATION.INVALID_LOGIN" />
              </div>
            </div>
          )
        ) : null}

        {location && location.state && location.state.message && (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">
              {location.state.message}
            </div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <GoogleLoginButton
            onClick={(e) => dispatch(googleAuth(setIsAuthenticated))}
          />
        </div>
        <Row>
          <Col>
            <hr />
          </Col>
          <Col xs={1}>
            <p className="text-center text-tertiary">or</p>
          </Col>
          <Col>
            <hr />
          </Col>
        </Row>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container position-relative">
          <input
            placeholder="Password"
            type={passwordShown ? "text" : "password"}
            className={` form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
          <span
            className="position-absolute"
            style={{
              right: "2.5rem",
              top: "1rem",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
            onClick={() => togglePasswordVisiblity()}
          >
            {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
          <span
            className="position-absolute"
            style={{
              right: "4.5rem",
              top: "1.5rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
            onClick={() => formik.setFieldValue("password", "")}
          >
            <AiFillCloseCircle />
          </span>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            className="mr-3"
            placeholder="keepMe"
            type="checkbox"
            name="checkbox"
            id="checkbox"
            {...formik.getFieldProps("keepMe")}
          />
          <label htmlFor="checkbox"> Keep me logged in.</label>
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(Login);
