import { StreamChat } from "stream-chat";

import axios from "axios";
import { getToken } from "../utils";
import * as actionTypes from "./actionTypes";

const newChatAxios = axios.create({
  baseURL: process.env.REACT_APP_STREAM_URL,
});

const initGetStreamToken = () => {
  return {
    type: actionTypes.INIT_GET_STREAM_TOKEN,
    loading: true,
  };
};

const successGetStreamToken = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_STREAM_TOKEN,
    loading: false,
    streamToken: data,
  };
};

const failGetStreamToken = (error) => {
  return {
    type: actionTypes.FAIL_GET_STREAM_TOKEN,
    loading: false,
    error: error,
  };
};

// const initConnectToStream = () => {
//   return {
//     type: actionTypes.INIT_CONNECT_TO_STREAM,
//     loading: true,
//   };
// };

const successConnectToStream = (data) => {
  return {
    type: actionTypes.SUCCESS_CONNECT_TO_STREAM,
    loading: false,
    chatClient: data,
  };
};

const failConnectToStream = (error) => {
  return {
    type: actionTypes.FAIL_CONNECT_TO_STREAM,
    loading: false,
    error: error,
  };
};

const initCheckUserRegistered = () => {
  return {
    type: actionTypes.INIT_CHECK_USER_REGISTERED,
    loading: true,
  };
};

const successCheckUserRegistered = (data) => {
  return {
    type: actionTypes.SUCCESS_CHECK_USER_REGISTERED,
    loading: false,
    userRegistered: data,
  };
};

const failCheckUserRegistered = (error) => {
  return {
    type: actionTypes.FAIL_CHECK_USER_REGISTERED,
    loading: false,
    error: error,
  };
};

const initCreateChat = () => {
  return {
    type: actionTypes.INIT_CREATE_CHAT,
    loading: true,
  };
};

const successCreateChat = (data) => {
  return {
    type: actionTypes.SUCCESS_CREATE_CHAT,
    loading: false,
    chatChannel: data,
  };
};

const failCreateChat = (error) => {
  return {
    type: actionTypes.FAIL_CREATE_CHAT,
    loading: false,
    error: error,
  };
};

export const getStreamToken = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(initGetStreamToken());
    const { streamToken, chatClient } = state.streamChatData;
    const { user } = state.auth;
    if (user) {
      const token = getToken();
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      if (streamToken && chatClient) {
        try {
          dispatch(successGetStreamToken(streamToken));
        } catch (e) {
          dispatch(failGetStreamToken(e));
        }
      } else {
        try {
          const streamTokenResponse = await newChatAxios.post(
            "/createStreamUserAndGetToken",
            null,
            config
          );
          dispatch(successGetStreamToken(streamTokenResponse.data));
        } catch (e) {
          dispatch(failGetStreamToken(e));
        }
      }
    }
  };
};

export const connectToClient = () => {
  return async (dispatch, getState) => {
    const state = getState();
    //dispatch(initConnectToStream());
    const { streamToken, chatClient } = state.streamChatData;
    const { user } = state.auth;

    if (streamToken && user) {
      if (chatClient) {
        dispatch(successConnectToStream(chatClient));
      } else {
        const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_KEY);
        try {
          await client.connectUser(
            {
              id: user.firebase_uid,
              name: user.name ?? "Guest",
              image: user.company_banner,
            },
            `${streamToken}`
          );
          dispatch(successConnectToStream(client));
        } catch (e) {
          dispatch(failConnectToStream(e));
        }
      }
    }
  };
};

export const checkUserRegistered = (type, receiverIdentifier) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { chatClient } = state.streamChatData;
    const token = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    dispatch(initCheckUserRegistered());
    if (type === "email" && chatClient) {
      try {
        const response = await newChatAxios.post(
          "/getUserByEmail",
          { email: receiverIdentifier },
          config
        );

        if (response.data.data === null) {
          dispatch(failCheckUserRegistered("User not found."));
        }

        const findUser = await chatClient.queryUsers(
          {
            id: { $in: [`${response.data.data.uid}`] },
          },
          { offset: 0 },
          { limit: 1 }
        );

        //console.log(response.data.data.uid, findUser);

        if (findUser.users.length > 0) {
          dispatch(successCheckUserRegistered(findUser.users[0]));
        } else {
          dispatch(failCheckUserRegistered("User not registered to chat."));
        }
      } catch (e) {
        dispatch(failCheckUserRegistered(e));
      }
    } else if (type === "uid" && chatClient) {
      try {
        const findUser = await chatClient.queryUsers({
          id: { $in: [receiverIdentifier] },
          sort: [],
          offset: 0,
          limit: 1,
        });

        if (findUser.users.length > 0) {
          dispatch(successCheckUserRegistered(findUser.users[0]));
        } else {
          dispatch(failCheckUserRegistered("User not registered to chat."));
        }
      } catch (e) {
        dispatch(failCheckUserRegistered(e));
      }
    }
  };
};

export const createChat = (receiverUID, channelType) => {
  return async (dispatch, getState) => {
    dispatch(initCreateChat());
    const state = getState();
    const { chatClient } = state.streamChatData;
    const { user } = state.auth;
    try {
      if (user === null) {
        throw Error("User not authenticated.");
      }

      if (chatClient) {
        const chatChannel = chatClient.channel(channelType, {
          members: [user.firebase_uid, receiverUID],
        });

        dispatch(successCreateChat(chatChannel));
      }
    } catch (e) {
      dispatch(failCreateChat(e));
    }
  };
};
