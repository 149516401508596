import * as actionTypes from "../../actions/actionTypes";

const initState = {
  submiting: false,
  success: null,
  errorSubmit: null
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_ADD_PORDUCT:
      return { ...state, submiting: action.submiting };
    case actionTypes.SUCCESS_ADD_PORDUCT:
      return { ...state, submiting: action.submiting, success: action.success };
    case actionTypes.FAIL_ADD_PORDUCT:
      return {
        ...state,
        submiting: action.submiting,
        errorSubmit: action.errorSubmit
      };
    default:
      return { ...state };
  }
};

export default Reducer;
