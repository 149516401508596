// Authentication :

export const INIT_LOG_IN = "INIT_LOG_IN";
export const SUCCESS_LOG_IN = "SUCCESS_LOG_IN";
export const FAIL_LOG_IN = "FAIL_LOG_IN";
export const KEEP_USER = "KEEP_USER";
// Profile :

export const INIT_GET_PROFILE = "INIT_GET_PROFILE";
export const SUCCESS_GET_PROFILE = "SUCCESS_GET_PROFILE";
export const FAIL_GET_PROFILE = "FAIL_GET_PROFILE";

//VENDOR :

export const INIT_GET_VERNDOR = "INIT_GET_VERNDOR";
export const SUCCESS_GET_VERNDOR = "SUCCESS_GET_VERNDOR";
export const FAIL_GET_VERNDOR = "FAIL_GET_VERNDOR";

// Get Products :

export const INIT_GET_PRODUCTS = "INIT_GET_PRODUCTS";
export const SUCCESS_GET_PRODUCTS = "SUCCESS_GET_PRODUCTS";
export const FAIL_GET_PRODUCTS = "FAIL_GET_PRODUCTS";

// Get Product:

export const INIT_GET_PRODUCT = "INIT_GET_PRODUCT";
export const SUCCESS_GET_PRODUCT = "SUCCESS_GET_PRODUCT";
export const FAIL_GET_PRODUCT = "FAIL_GET_PRODUCT";

// Generate Attribute :
export const INIT_GENERATE_ATTRIBUTE = "INIT_GENERATE_ATTRIBUTE";
export const SUCCESS_GENERATE_ATTRIBUTE = "SUCCESS_GENERATE_ATTRIBUTE";
export const FAIL_GENERATE_ATTRIBUTE = "FAIL_GENERATE_ATTRIBUTE";
export const RESET_GENERATE_ATTRIBUTE = "RESET_GENERATE_ATTRIBUTE";

//add Product :

export const ADD_FIELD = "ADD_FIELD";
export const ADD_FIELDS = "ADD_FIELDS";
export const ADD_CHILD_INDEX = "ADD_CHILD_INDEX";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const RESET_FEILDS = "RESET_FEILDS";
//add Product Validation:

export const ADD_FIELD_VALID = "ADD_FIELD_VALID";
export const ADD_FIELDS_VALID = "ADD_FIELDS_VALID";
/****Submit form */

export const INIT_ADD_PORDUCT = "INIT_ADD_PORDUCT";
export const SUCCESS_ADD_PORDUCT = "SUCCESS_ADD_PORDUCT";
export const FAIL_ADD_PORDUCT = "FAIL_ADD_PORDUCT";

// Get Product Options :

export const INIT_GET_OPTION = "INIT_GET_OPTION";
export const SUCCESS_GET_VISIBILITY = "SUCCESS_GET_VISIBILITY";
export const SUCCESS_GET_PRODUCT_TYPE = "SUCCESS_GET_PRODUCT_TYPE";
export const FAIL_GET_OPTION = "FAIL_GET_OPTION";

// Get Categories :
export const INIT_GET_CATEGORIES = "INIT_GET_CATEGORIES";
export const SUCCESS_GET_CATEGORIES = "SUCCESS_GET_CATEGORIES";
export const FAIL_GET_CATEGORIES = "FAIL_GET_CATEGORIES";

// Attribute Group:

export const INIT_GET_ATTRIBUTE_GROUP = "INIT_GET_ATTRIBUTE_GROUP";
export const SUCCESS_GET_ATTRIBUTE_GROUP = "SUCCESS_GET_ATTRIBUTE_GROUP";
export const FAIL_GET_ATTRIBUTE_GROUP = "FAIL_GET_ATTRIBUTE_GROUP";
//Single Attribute:
export const INIT_GET_ATTRIBUTE = "INIT_GET_ATTRIBUTE";
export const SUCCESS_GET_ATTRIBUTE = "SUCCESS_GET_ATTRIBUTE";
export const FAIL_GET_ATTRIBUTE = "FAIL_GET_ATTRIBUTE";

// Catalog Attribute :
export const INIT_GET_CATALOG_ATTRIBUTE = "INIT_GET_CATALOG_ATTRIBUTE";
export const SUCCESS_GET_CATALOG_ATTRIBUTE = "SUCCESS_GET_CATALOG_ATTRIBUTE";
export const FAIL_GET_CATALOG_ATTRIBUTE = "FAIL_GET_CATALOG_ATTRIBUTE";

//post Catalog Attribute :
export const INIT_POST_CATALOG_ATTRIBUTE = "INIT_POST_CATALOG_ATTRIBUTE";
export const SUCCESS_POST_CATALOG_ATTRIBUTE = "SUCCESS_POST_CATALOG_ATTRIBUTE";
export const FAIL_POST_CATALOG_ATTRIBUTE = "FAIL_POST_CATALOG_ATTRIBUTE";
export const RESET_POST_CATALOG = "RESET_POST_CATALOG";

//Get Catalog Attribute details:

export const INIT_GET_CATALOG_ATTRIBUTE_DETAILS =
  "INIT_GET_CATALOG_ATTRIBUTE_DETAILS";
export const SUCCESS_GET_CATALOG_ATTRIBUTE_DETAILS =
  "SUCCESS_GET_CATALOG_ATTRIBUTE_DETAILS";
export const FAIL_GET_CATALOG_ATTRIBUTE_DETAILS =
  "FAIL_GET_CATALOG_ATTRIBUTE_DETAILS";
export const RESET_ATTRIBUTE_DETAILS = "RESET_ATTRIBUTE_DETAILS";

// Catalog Categories :
//Get Catalog Categories

export const INIT_GET_CATALOG_CATEGORIES = "INIT_GET_CATALOG_CATEGORIES";
export const SUCCESS_GET_CATALOG_CATEGORIES = "SUCCESS_GET_CATALOG_CATEGORIES";
export const FAIL_GET_CATALOG_CATEGORIES = "FAIL_GET_CATALOG_CATEGORIES";
export const RESET_CATALOG_CATEGORIES = "RESET_CATALOG_CATEGORIES";

//post Catalog Categories:
export const INIT_POST_CATALOG_CATEGORY = "INIT_POST_CATALOG_CATEGORY";
export const SUCCESS_POST_CATALOG_CATEGORY = "SUCCESS_POST_CATALOG_CATEGORY";
export const FAIL_POST_CATALOG_CATEGORY = "FAIL_POST_CATALOG_CATEGORY";
export const RESET_POST_CATALOG_CATEGORY = "RESET_POST_CATALOG_CATEGORY";

// get catalog category detail:

export const INIT_GET_CATALOG_CATEGORY_DETAILS =
  "INIT_GET_CATALOG_CATEGORY_DETAILS";
export const SUCCESS_GET_CATALOG_CATEGORY_DETAILS =
  "SUCCESS_GET_CATALOG_CATEGORY_DETAILS";
export const FAIL_GET_CATALOG_CATEGORY_DETAILS =
  "FAIL_GET_CATALOG_CATEGORY_DETAILS";
export const RESET_GET_CATALOG_CATEGORY_DETAILS =
  "RESET_GET_CATALOG_CATEGORY_DETAILS";

// GEt catalog category products:

export const INIT_GET_TAG_CATALOG_CATEGORY_PRODUCTS =
  "INIT_GET_TAG_CATALOG_CATEGORY_PRODUCTS";
export const SUCCESS_GET_TAG_CATALOG_CATEGORY_PRODUCTS =
  "SUCCESS_GET_TAG_CATALOG_CATEGORY_PRODUCTS";
export const FAIL_GET_TAG_CATALOG_CATEGORY_PRODUCTS =
  "FAIL_GET_TAG_CATALOG_CATEGORY_PRODUCTS";
export const RESET_GET_TAG_CATALOG_CATEGORY_PRODUCTS =
  "RESET_GET_TAG_CATALOG_CATEGORY_PRODUCTS";

// submit catalog category products:

export const INIT_POST_TAG_CATALOG_CATEGORY_PRODUCTS =
  "INIT_POST_TAG_CATALOG_CATEGORY_PRODUCTS";
export const SUCCESS_POST_TAG_CATALOG_CATEGORY_PRODUCTS =
  "SUCCESS_POST_TAG_CATALOG_CATEGORY_PRODUCTS";
export const FAIL_POST_TAG_CATALOG_CATEGORY_PRODUCTS =
  "FAIL_POST_TAG_CATALOG_CATEGORY_PRODUCTS";
export const RESET_POST_TAG_CATALOG_CATEGORY_PRODUCTS =
  "RESET_POST_TAG_CATALOG_CATEGORY_PRODUCTS";
// get catalog promotions:

export const INIT_GET_PROMOTIONS = "INIT_GET_PROMOTIONS";
export const SUCCESS_GET_PROMOTIONS = "SUCCESS_GET_PROMOTIONS";
export const FAIL_GET_PROMOTIONS = "FAIL_GET_PROMOTIONS";
//promo types
export const SUCCESS_GET_PROMOTIONS_TYPES = "SUCCESS_GET_PROMOTIONS_TYPES";

// Dashboard:

export const INIT_GET_DASHBOARD = "INIT_GET_DASHBOARD";
export const SUCCSESS_GET_DASHBOARD = "SUCCSESS_GET_DASHBOARD";
export const FAIL_GET_DASHBOARD = "FAIL_GET_DASHBOARD";
//tolken:
//get balance;
export const INIT_GET_VENDOR_BALANCE = "INIT_GET_VENDOR_BALANCE";
export const SUCCESS_GET_VENDOR_BALANCE = "SUCCESS_GET_VENDOR_BALANCE";
export const FAIL_GET_VENDOR_BALANCE = "FAIL_GET_VENDOR_BALANCE";
//add transaction:
export const INIT_GET_TRANSACTION = "INIT_GET_TRANSACTION";
export const SUCCESS_GET_TRANSACTION = "SUCCESS_GET_TRANSACTION";
export const FAIL_GET_TRANSACTION = "FAIL_GET_TRANSACTION";
//addvertise:
export const INIT_GET_ADDVERTISMENT = "INIT_GET_ADDVERTISMENT";
export const SUCCESS_GET_ADDVERTISMENT = "SUCCESS_GET_ADDVERTISMENT";
export const FAIL_GET_ADDVERTISMENT = "FAIL_GET_ADDVERTISMENT";
export const SUCCESS_GET_ADDVERTISMENT_TYPES =
  "SUCCESS_GET_ADDVERTISMENT_TYPES";
//orders:
export const INIT_GET_ORDERS = "INIT_GET_ORDERS";
export const SUCCESS_GET_ORDERS = "SUCCESS_GET_ORDERS";
export const FAIL_GET_ORDERS = "FAIL_GET_ORDERS";
//order:
export const INIT_GET_ORDER = "INIT_GET_ORDER";
export const SUCCESS_GET_ORDER = "SUCCESS_GET_ORDER";
export const FAIL_GET_ORDER = "FAIL_GET_ORDER";
export const RESET_ORDER = "RESET_ORDER";
//shipping vendors:
export const INIT_GET_SHIPPING_VENDORS = "INIT_GET_SHIPPING_VENDORS";
export const SUCCESS_GET_SHIPPING_VENDORS = "SUCCESS_GET_SHIPPING_VENDORS";
export const FAIL_GET_SHIPPING_VENDORS = "FAIL_GET_SHIPPING_VENDORS";
//shipping config:
export const INIT_GET_SHIPPERS = "INIT_GET_SHIPPERS";
export const SUCCESS_GET_SHIPPERS = "SUCCESS_GET_SHIPPERS";
export const FAIL_GET_SHIPPERS = "FAIL_GET_SHIPPERS";

//STREAM CHATS
export const INIT_GET_STREAM_TOKEN = "INIT_GET_STREAM_TOKEN";
export const SUCCESS_GET_STREAM_TOKEN = "SUCCESS_GET_STREAM_TOKEN";
export const FAIL_GET_STREAM_TOKEN = "FAIL_GET_STREAM_TOKEN";

export const INIT_CONNECT_TO_STREAM = "INIT_CONNECT_TO_STREAM";
export const SUCCESS_CONNECT_TO_STREAM = "SUCCESS_CONNECT_TO_STREAM";
export const FAIL_CONNECT_TO_STREAM = "FAIL_CONNECT_TO_STREAM";

export const INIT_CHECK_USER_REGISTERED = "INIT_CHECK_USER_REGISTERED";
export const SUCCESS_CHECK_USER_REGISTERED = "SUCCESS_CHECK_USER_REGISTERED";
export const FAIL_CHECK_USER_REGISTERED = "FAIL_CHECK_USER_REGISTERED";

export const INIT_CREATE_CHAT = "INIT_CREATE_CHAT";
export const SUCCESS_CREATE_CHAT = "SUCCESS_CREATE_CHAT";
export const FAIL_CREATE_CHAT = "FAIL_CREATE_CHAT";

//RATING
export const INIT_GET_RATING = "INIT_GET_RATING";
export const SUCCESS_GET_RATING = "SUCCESS_GET_RATING";
export const FAIL_GET_RATING = "FAIL_GET_RATING";

export const INIT_GET_RATING_COUNT = "INIT_GET_RATING_COUNT";
export const SUCCESS_GET_RATING_COUNT = "SUCCESS_GET_RATING_COUNT";
export const FAIL_GET_RATING_COUNT = "FAIL_GET_RATING_COUNT";

export const UPDATE_RATING_PARAM = "UPDATE_RATING_PARAM";


