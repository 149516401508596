import * as actionTypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  error: null,
  //
  submitting: false,
  success: null,
  submitError: null
};

const initGetCatalogCategoryProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetCatalogCategoryProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data
  };
};

const failGetCatalogCategoryProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_TAG_CATALOG_CATEGORY_PRODUCTS:
      return initGetCatalogCategoryProducts(state, action);
    case actionTypes.SUCCESS_GET_TAG_CATALOG_CATEGORY_PRODUCTS:
      return successGetCatalogCategoryProducts(state, action);
    case actionTypes.FAIL_GET_TAG_CATALOG_CATEGORY_PRODUCTS:
      return failGetCatalogCategoryProducts(state, action);
    //post
    case actionTypes.INIT_POST_TAG_CATALOG_CATEGORY_PRODUCTS:
      return {
        ...state,
        submitting: action.submitting,
        success: action.success,
        error: action.error
      };
    case actionTypes.SUCCESS_POST_TAG_CATALOG_CATEGORY_PRODUCTS:
      return {
        ...state,
        submitting: action.submitting,
        success: action.success
      };
    case actionTypes.FAIL_POST_TAG_CATALOG_CATEGORY_PRODUCTS:
      return {
        ...state,
        submitting: action.submitting,
        submitError: action.error
      };
    case actionTypes.RESET_POST_TAG_CATALOG_CATEGORY_PRODUCTS:
      return { ...initState };

    default:
      return { ...state };
  }
};

export default Reducer;
