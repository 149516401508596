import * as actionTypes from "../../actions/actionTypes";

const initState = {
  status: false,
  sku: "",
  name: "",
  type: "",
  product_visibility: "4",
  description: "",
  short_description: "",
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  price: "",
  cost: "",
  stock: "",
  weight: "",
  category_id: "",
  category: [],
  attribute: [],
  child: [],
  image: [],
  thumbnail: {},
  video_url: ""
};

const addFeild = (state, action) => {
  return {
    ...state,
    [`${action.key}`]: action.value
  };
};

const addFeilds = (state, action) => {
  return {
    ...state,
    ...action.feilds
  };
};

const addChildIndex = (state, action) => {
  const updtaedState = { ...state };
  const updtaedChild = [...updtaedState.child];
  updtaedChild[action.index] = action.value;
  updtaedState.child = updtaedChild;
  return {
    ...updtaedState
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FIELD:
      return addFeild(state, action);
    case actionTypes.ADD_FIELDS:
      return addFeilds(state, action);
    case actionTypes.ADD_CHILD_INDEX:
      return addChildIndex(state, action);
    case actionTypes.RESET_FEILDS:
      return { ...initState };
    default:
      return { ...state };
  }
};

export default Reducer;
