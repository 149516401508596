import * as actionTypes from "../actionTypes";

export const addFieldValid = (name, value) => {
  return {
    type: actionTypes.ADD_FIELD_VALID,
    key: name,
    value: value
  };
};

export const addFieldsValid = obj => {
  return {
    type: actionTypes.ADD_FIELDS_VALID,
    feilds: obj
  };
};
