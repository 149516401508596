import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getToken } from "../utils";

const initGetCategories = () => {
  return {
    type: actionTypes.INIT_GET_CATEGORIES,
    loading: true,
  };
};

const successGetCategories = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_CATEGORIES,
    data: data,
    loading: false,
  };
};

const failGetCategories = (error) => {
  return {
    type: actionTypes.FAIL_GET_CATEGORIES,
    loading: false,
    error: error,
  };
};

export const getCategories = () => {
  return (dispatch) => {
    dispatch(initGetCategories());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    axios
      .get("category/", config)
      .then((response) => dispatch(successGetCategories(response.data.data)))
      .catch((err) => {
        dispatch(failGetCategories(err.message));
        console.log("###response", err.response);
      });
  };
};
