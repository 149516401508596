import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  error: null
};

const initGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data
  };
};

const failGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRODUCTS:
      return initGetProducts(state, action);
    case actionTypes.SUCCESS_GET_PRODUCTS:
      return successGetProducts(state, action);
    case actionTypes.FAIL_GET_PRODUCTS:
      return failGetProducts(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
