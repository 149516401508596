import axios from "axios";

import { getToken } from "../../utils";
import * as actionTypes from "../actionTypes";

const initGetCatalogCategories = () => {
  return {
    type: actionTypes.INIT_GET_CATALOG_CATEGORIES,
    loading: true
  };
};
const successGetCatalogCategories = data => {
  return {
    type: actionTypes.SUCCESS_GET_CATALOG_CATEGORIES,
    data: data,
    loading: false
  };
};
const failGetCatalogCategories = error => {
  return {
    type: actionTypes.FAIL_GET_CATALOG_CATEGORIES,
    error: error,
    loading: false
  };
};

export const getCatalogCategories = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  return dispatch => {
    dispatch(initGetCatalogCategories());
    axios
      .get("vendor/category", config)
      .then(response =>
        dispatch(successGetCatalogCategories(response.data.data))
      )
      .catch(err => dispatch(failGetCatalogCategories(err.message)));
  };
};

export const deleteCatalogCategories = id => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    axios
      .delete("vendor/category?id=" + id, config)
      .then(response => dispatch(getCatalogCategories()))
      .catch(err => console.log(err.message));
  };
};
