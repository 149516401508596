import * as actionTypes from "../../actions/actionTypes";

const initState = {
  getting: false,
  data: null,
  detailError: null,
  //post
  loading: false,
  success: null,
  error: null
};

const initGetCategoryDetail = (state, action) => {
  return {
    ...state,
    getting: action.getting
  };
};
const successGetCategoryDetail = (state, action) => {
  return {
    ...state,
    getting: action.getting,
    data: action.data
  };
};
const failGetCategoryDetail = (state, action) => {
  return {
    ...state,
    getting: action.getting,
    detailError: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CATALOG_CATEGORY_DETAILS:
      return initGetCategoryDetail(state, action);
    case actionTypes.SUCCESS_GET_CATALOG_CATEGORY_DETAILS:
      return successGetCategoryDetail(state, action);
    case actionTypes.FAIL_GET_CATALOG_CATEGORY_DETAILS:
      return failGetCategoryDetail(state, action);
    case actionTypes.RESET_GET_CATALOG_CATEGORY_DETAILS:
      return { ...initState };
    //post:
    case actionTypes.INIT_POST_CATALOG_CATEGORY:
      return { ...state, loading: action.loading };
    case actionTypes.SUCCESS_POST_CATALOG_CATEGORY:
      return { ...state, loading: action.loading, success: action.success };
    case actionTypes.FAIL_POST_CATALOG_CATEGORY:
      return { ...state, loading: action.loading, error: action.error };
    case actionTypes.RESET_POST_CATALOG_CATEGORY:
      return { ...initState };
    default:
      return { ...state };
  }
};

export default Reducer;
