import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_vendor: false,
  vendor: null,
  error_vendor: null
};

const initGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor
  };
};
const successGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor,
    vendor: action.vendor
  };
};
const failGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor,
    error_vendor: action.error_vendor
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_VERNDOR:
      return initGetVendor(state, action);
    case actionTypes.SUCCESS_GET_VERNDOR:
      return successGetVendor(state, action);
    case actionTypes.FAIL_GET_VERNDOR:
      return failGetVendor(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
