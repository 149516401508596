import axios from "axios";
import * as actionTypes from "../actionTypes";
import { getToken } from "../../utils";
import { getProducts } from "../index";

export const addField = (name, value) => {
  return {
    type: actionTypes.ADD_FIELD,
    key: name,
    value: value,
  };
};

export const addFields = (obj) => {
  return {
    type: actionTypes.ADD_FIELDS,
    feilds: obj,
  };
};

export const submitForm = (data, edit) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  if (data.status === true) data.status = 1;
  else data.status = 0;

  delete data.category_id;

  data.child = data.child.filter((ch) => {
    return typeof ch === "object" && ch !== null;
  });
  
  const stringifyArr = ["category", "attribute", "child", "delete_image"];

  const bodyFormData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (stringifyArr.includes(key))
      bodyFormData.append(key, JSON.stringify(data[key]));
    else if (key === "image") {
      value.forEach((img, i) => bodyFormData.append(`image[${i}]`, img));
    } else bodyFormData.append(key, value);
  });

  if (edit) {
    if (data.category.length === 0) bodyFormData.delete("category");
    // bodyFormData.append('status', JSON.stringify(data.status))
    bodyFormData.append("product_id", JSON.stringify(edit.id));
    bodyFormData.append("_method", "PUT");

    for (let [key, value] of bodyFormData.entries()) {
      if (value === "[object Object]") bodyFormData.delete(key);
      // console.log(key, value)
    }

    return (dispatch) => {
      dispatch({ type: actionTypes.INIT_ADD_PORDUCT, submiting: true });
      axios
        .post("product", bodyFormData, config)
        .then((response) => {
          dispatch(getProducts());
          dispatch({
            type: actionTypes.SUCCESS_ADD_PORDUCT,
            submiting: false,
            success: true,
          });
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.FAIL_ADD_PORDUCT,
            submiting: false,
            errorSubmit: err?.response?.data?.messages ?? [
              "Oops..., somthing went wrong",
            ],
          });
          if (err.response) {
            console.log("###response", err.response);
          } else if (err.request) {
            console.log("###request", err.request);
          } else if (err.message) {
            console.log("###message", err.message);
          }
        });
    };
  } else {
    return (dispatch) => {
      dispatch({ type: actionTypes.INIT_ADD_PORDUCT, submiting: true });
      axios
        .post("product", bodyFormData, config)
        .then((response) => {
          dispatch(getProducts());
          dispatch({
            type: actionTypes.SUCCESS_ADD_PORDUCT,
            submiting: false,
            success: true,
          });
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.FAIL_ADD_PORDUCT,
            submiting: false,
            errorSubmit: err?.response?.data?.messages ?? [
              "Oops..., somthing went wrong",
            ],
          });
          if (err.response) {
            console.log("###response", err.response);
          } else if (err.request) {
            // console.log("###request", err.request);
          } else if (err.message) {
            console.log("###message", err.message);
          }
        });
    };
  }
};

export const addChildIndex = (index, obj) => {
  return {
    type: actionTypes.ADD_CHILD_INDEX,
    index: index,
    value: obj,
  };
};
