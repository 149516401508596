import * as actionTypes from "../actions/actionTypes";

const initState = {
  rating_loading: false,
  rating_data: [],
  rating_next_page: 0,
  rating_error: null,
  //COUNT
  rating_count_loading: false,
  rating_count_data: null,
  rating_count_error: null,
  //param
  rating_param: {
    page: 1,
    rating_filter: "rating_all",
  },
};

const initGetRatings = (state, action) => {
  return {
    ...state,
    rating_loading: action.rating_loading,
  };
};

const successGetRatings = (state, action) => {
  return {
    ...state,
    rating_loading: action.rating_loading,
    rating_data: action.rating_data,
    rating_next_page: action.rating_next_page,
    rating_error: action.rating_error,
  };
};

const failGetRatings = (state, action) => {
  return {
    ...state,
    rating_loading: action.rating_loading,
    rating_data: action.rating_data,
    rating_next_page: action.rating_next_page,
    rating_error: action.rating_error,
  };
};

const initGetRatingsCount = (state, action) => {
  return {
    ...state,
    rating_count_loading: action.rating_count_loading,
  };
};

const successGetRatingsCount = (state, action) => {
  return {
    ...state,
    rating_count_loading: action.rating_count_loading,
    rating_count_data: action.rating_count_data,
    rating_count_error: action.rating_count_error,
  };
};
const failGetRatingsCount = (state, action) => {
  return {
    ...state,
    rating_count_loading: action.rating_count_loading,
    rating_count_data: action.rating_count_data,
    rating_count_error: action.rating_count_error,
  };
};

const updateRatingsParam = (state, action) => {
  const prevParam = JSON.parse(JSON.stringify(state.rating_param));
  return {
    ...state,
    rating_param: {
      ...prevParam,
      [action.key]: action.val,
    },
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_RATING:
      return initGetRatings(state, action);
    case actionTypes.SUCCESS_GET_RATING:
      return successGetRatings(state, action);
    case actionTypes.FAIL_GET_RATING:
      return failGetRatings(state, action);
    case actionTypes.INIT_GET_RATING_COUNT:
      return initGetRatingsCount(state, action);
    case actionTypes.SUCCESS_GET_RATING_COUNT:
      return successGetRatingsCount(state, action);
    case actionTypes.FAIL_GET_RATING_COUNT:
      return failGetRatingsCount(state, action);
    case actionTypes.UPDATE_RATING_PARAM:
      return updateRatingsParam(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
