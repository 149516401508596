import * as actionTypes from "../../actions/actionTypes";

const initState = {
  //sku: false,
  name: false,
  type: false,
  //product_visibility: false,
  description: false,
  short_description: false,
  price: false,
  cost: false,
  stock: false,
  category_id: false,
  image: false,
  thumbnail: false,
  child: true
};

const addFeild = (state, action) => {
  return {
    ...state,
    [`${action.key}`]: action.value
  };
};

const addFeilds = (state, action) => {
  return {
    ...state,
    ...action.feilds
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FIELD_VALID:
      return addFeild(state, action);
    case actionTypes.ADD_FIELDS_VALID:
      return addFeilds(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
