/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { app } from "../Firebase/firebase";
import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";

import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthPage from "./modules/Auth/AuthPage";
import { getToken } from "../redux/utils";
import { useDispatch, useSelector } from "react-redux";
import { failLogIn, getProfile, successFirebaseLogIn } from "../redux/actions";
import RequiredForm from "./modules/UserProfile/RequiredForm";

export function Routes() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getToken() !== null ?? null
  );
  const { loading_user, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth(app);
    let refreshMyToken = null;
    
    onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified && isAuthenticated) {
        const storage = localStorage.getItem("keep");

        if (storage === "true") {
          localStorage.setItem("email", user.email);
          localStorage.setItem("idToken", user.accessToken);
        } else {
          sessionStorage.setItem("email", user.email);
          sessionStorage.setItem("idToken", user.accessToken);
        }

        dispatch(successFirebaseLogIn(user));
        dispatch(getProfile(true, user));

        refreshMyToken = setInterval(() => {
          user.getIdToken(true).then((newToken) => {
            if (storage === "true") localStorage.setItem("idToken", newToken);
            else sessionStorage.setItem("idToken", newToken);
          });
        }, 3590 * 1000);
      } else if (user && !user.emailVerified) {
        localStorage.removeItem("idToken");
        sessionStorage.removeItem("idToken");
        setIsAuthenticated(false)
        dispatch(
          failLogIn(
            "Your account is not verified, please check your email to verfiy"
          )
        );
        sendEmailVerification(user)
      } else {
        localStorage.removeItem("idToken");
        sessionStorage.removeItem("idToken");
      }
    });
    return () => {
      if (refreshMyToken) clearInterval(refreshMyToken);
    };
  }, [dispatch, isAuthenticated]);

  const checkValidUser = () => {
    let valid = true;
    if (user) {
      valid =
        valid &&
        user.shop_name &&
        user.company_name &&
        user.company_email &&
        user.company_address_1 &&
        user.company_address_2 &&
        user.company_address_postcode &&
        user.company_address_city &&
        user.company_address_state &&
        user.company_address_country;
    } else valid = false;
    return valid ? true : false;
  };

  return loading_user ? (
    <LayoutSplashScreen visible={true} />
  ) : isAuthenticated && checkValidUser() ? (
    <Switch>
      <Layout>
        <BasePage />
      </Layout>
    </Switch>
  ) : isAuthenticated && !checkValidUser() ? (
    <Route component={RequiredForm} />
  ) : (
    <Switch>
      <Route>
        <AuthPage setIsAuthenticated={setIsAuthenticated} />
      </Route>
    </Switch>
  );
}
