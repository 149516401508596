//GetRatings
import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getToken } from "../utils";

const initGetRatings = () => {
  return {
    type: actionTypes.INIT_GET_RATING,
    rating_loading: true,
  };
};

const successGetRatings = (data) => {
  let resData = data;
  if ("products_review" in data) {
    resData = { review: { data: [] } };
    data.products_review.forEach((resItem) => {
      resData.review.data = [...resData.review.data, ...resItem.review.data];
    });
  }

  return {
    type: actionTypes.SUCCESS_GET_RATING,
    rating_loading: false,
    rating_data: resData.review.data,
    rating_next_page:
      resData.review.last_page - resData.review.current_page > 0
        ? resData.review.current_page + 1
        : 0,
    rating_error: null,
  };
};

const failGetRatings = (data) => {
  return {
    type: actionTypes.FAIL_GET_RATING,
    rating_loading: false,
    rating_data: null,
    rating_next_page: 0,
    rating_error: data,
  };
};

export const getRatings = () => {
  return (dispatch, getState) => {
    const { rating_param } = getState().rating;
    dispatch(initGetRatings());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    axios
      .post("rating", rating_param, config)
      .then((response) => dispatch(successGetRatings(response.data.data)))

      .catch((err) => dispatch(failGetRatings(err.message)))
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

const initGetRatingsCount = () => {
  return {
    type: actionTypes.INIT_GET_RATING_COUNT,
    rating_count_loading: true,
  };
};

const successGetRatingsCount = (data) => {
  const rateCount = {
    rating_all: data.rating_all,
    rating_5: data.rating_5,
    rating_4: data.rating_4,
    rating_3: data.rating_3,
    rating_2: data.rating_2,
    rating_1: data.rating_1,
  };
  return {
    type: actionTypes.SUCCESS_GET_RATING_COUNT,
    rating_count_loading: false,
    rating_count_data: rateCount,
    rating_count_error: null,
  };
};

const failGetRatingsCount = (data) => {
  return {
    type: actionTypes.FAIL_GET_RATING_COUNT,
    rating_count_loading: false,
    rating_count_data: null,
    rating_count_error: data,
  };
};

export const getRatingsCount = () => {
  return (dispatch) => {
    dispatch(initGetRatingsCount());

    const idToken = getToken();
    const config = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    axios
      .post("rating/counter", undefined, config)
      .then((response) => dispatch(successGetRatingsCount(response.data.data)))

      .catch((err) => dispatch(failGetRatingsCount(err.message)))
      .catch((err) => {
        console.log("###response", err.response);
      });
  };
};

export const updateRateParam = (key, val) => {
  return { type: actionTypes.UPDATE_RATING_PARAM, key, val };
};
