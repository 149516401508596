import * as actiontypes from "../../actions/actionTypes";

const initState = {
  loading: false,
  attributeGroup: [],
  error: null,

  loading_1: false,
  attribute_1: [],
  error_1: null,

  loading_2: false,
  attribute_2: [],
  error_2: null,

  loading_3: false,
  attribute_3: [],
  error_3: null,

  loading_4: false,
  attribute_4: [],
  error_4: null
};

//Group
const initGetAttributeGroup = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetAttributeGroup = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    attributeGroup: action.attributeGroup
  };
};

const failGetAttributeGroup = (state, action) => {
  return {
    ...state,
    [`loading_${action.key}`]: action.loading,
    error: action.error
  };
};

//Single
const initGetAttribute = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};

const successGetAttribute = (state, action) => {
  return {
    ...state,
    [`loading_${action.key}`]: action.loading,
    [`attribute_${action.key}`]: action.data
  };
};

const failGetAttribute = (state, action) => {
  return {
    ...state,
    [`loading_${action.key}`]: action.loading,
    [`error_${action.key}`]: action.error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    //Group
    case actiontypes.INIT_GET_ATTRIBUTE_GROUP:
      return initGetAttributeGroup(state, action);
    case actiontypes.SUCCESS_GET_ATTRIBUTE_GROUP:
      return successGetAttributeGroup(state, action);
    case actiontypes.FAIL_GET_ATTRIBUTE_GROUP:
      return failGetAttributeGroup(state, action);
    //Single
    case actiontypes.INIT_GET_ATTRIBUTE:
      return initGetAttribute(state, action);
    case actiontypes.SUCCESS_GET_ATTRIBUTE:
      return successGetAttribute(state, action);
    case actiontypes.FAIL_GET_ATTRIBUTE:
      return failGetAttribute(state, action);

    default:
      return { ...state };
  }
};

export default Reducer;
