import axios from "axios";

import { getToken } from "../../utils";
import * as actionTypes from "../actionTypes";

const initGetCatalogAttriubte = () => {
  return {
    type: actionTypes.INIT_GET_CATALOG_ATTRIBUTE,
    loading: true
  };
};
const successGetCatalogAttriubte = data => {
  return {
    type: actionTypes.SUCCESS_GET_CATALOG_ATTRIBUTE,
    data: data,
    loading: false
  };
};
const failGetCatalogAttriubte = error => {
  return {
    type: actionTypes.FAIL_GET_CATALOG_ATTRIBUTE,
    error: error,
    loading: false
  };
};

export const getCatalogAttribute = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  return dispatch => {
    dispatch(initGetCatalogAttriubte());
    axios
      .get("attribute-group/", config)
      .then(response =>
        dispatch(successGetCatalogAttriubte(response.data.data))
      )
      .catch(err => dispatch(failGetCatalogAttriubte(err.message)));
  };
};

//Delete catalog attribute:

export const deleteCatalogAttribute = id => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    axios
      .delete("attribute-group?id=" + id, config)
      .then(response => dispatch(getCatalogAttribute()))
      .catch(err => console.log(err.message));
  };
};
