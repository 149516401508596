/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Chat, ChannelList } from "stream-chat-react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserNotificationsDropdown() {
  const toggleRef = useRef();
  const [mountMenu, setMountMenu] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch;
  const { chatClient } = useSelector((state) => state.streamChatData);
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(0);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [filters, setFilters] = useState({
    type: "shopping",
    members: { $in: [""] },
  });
  const sort = { last_message_at: -1 };

  useEffect(() => {
    if (chatClient) {
      chatClient.on((event) => {
        if (![null, undefined].includes(event.total_unread_count)) {
          if (event.total_unread_count > 0) setShow(event.total_unread_count);
          else setShow(0);
        }
      });
    }
  }, [chatClient]);

  useEffect(() => {
    if (user) {
      setFilters((prevState) => ({
        ...prevState,
        members: {
          $in: [user.firebase_uid],
        },
      }));
    }
  }, [dispatch, user]);

  return (
    <Dropdown
      drop="down"
      alignRight
      ref={toggleRef}
      onClick={(e) => setMountMenu(!mountMenu)}
    >
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="kt_quick_notifications_toggle"
      >
        <div
          className={`btn btn-icon btn-clean btn-lg mr-1 ${
            show > 0 ? "pulse pulse-primary" : ""
          } `}
          id="kt_quick_notifications_toggle"
        >
          <span
            className="svg-icon svg-icon-xl svg-icon-primary"
            style={{ pointerEvents: "none" }}
          >
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Group-chat.svg"
              )}
            />
          </span>
          <span className="pulse-ring"></span>
          <span className="pulse-ring" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          {/** Head */}
          <div
            className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <h4 className="d-flex flex-center rounded-top">
              <span className="text-white">Unread Messages</span>
              <span
                className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
                onClick={(e) => {
                  if (toggleRef.current) {
                    toggleRef.current.click();
                  }
                  history.push({ pathname: "/messages", state: null });
                }}
              >
                {show} new
              </span>
            </h4>
            <p className="text-white ml-3 cursor-pointer">{!mountMenu ? "Show" : "Hide"} Chats</p>
          </div>
          {mountMenu && user && chatClient && (
            <Chat
              ch
              client={chatClient}
              customClasses={{
                chat: "w-100 d-none d-lg-block",
              }}
            >
              <ChannelList
                filters={filters}
                sort={sort}
                List={(props) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        pointerEvents: "none",
                        maxHeight: "36vh",
                        overflow: "hidden",
                      }}
                    >
                      {props.children}
                    </div>
                  );
                }}
              />
            </Chat>
          )}
          <span
            className="text-dark-50 font-weight-bolder font-size-base d-block text-center  ml-3 my-5 cursor-pointer"
            onClick={(e) => {
              if (toggleRef.current) {
                toggleRef.current.click();
              }
              history.push({ pathname: "/messages", state: null });
            }}
          >
            View All Messages
          </span>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
}
