import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_transaction: false,
  data_transaction: [],
  error_transaction: null
};

const initTransaction = (state, action) => {
  return {
    ...state,
    loading_transaction: action.loading_transaction
  };
};
const successTransaction = (state, action) => {
  return {
    ...state,
    loading_transaction: action.loading_transaction,
    data_transaction: action.data_transaction,
    error_transaction: action.error_transaction
  };
};
const failTransaction = (state, action) => {
  return {
    ...state,
    loading_transaction: action.loading_transaction,
    data_transaction: action.data_transaction,
    error_transaction: action.error_transaction
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_TRANSACTION:
      return initTransaction(state, action);
    case actionTypes.SUCCESS_GET_TRANSACTION:
      return successTransaction(state, action);
    case actionTypes.FAIL_GET_TRANSACTION:
      return failTransaction(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
